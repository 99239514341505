import { FC, useState, useRef } from "react"
import { IUserDeviceData } from "../../../models/Response";
import { Line as LineCharJS } from 'react-chartjs-2';
import moment from "moment";
import CHART_COLORS from "../../../constants/ChartColors";
import zoomPlugin from 'chartjs-plugin-zoom';
import Chart from 'chart.js/auto';

// Register the zoom plugin
Chart.register(zoomPlugin);

interface Props {
  data: IUserDeviceData[];
  statik: "0" | "1"
}

interface ChartRefType {
  resetZoom: () => void;
}

const MagChartJS: FC<Props> = ({ data, statik }) => {
  const [chartData] = useState<IUserDeviceData[]>(data);
  const chartRef = useRef<ChartRefType | null>(null); // Specify the type of chartRef

  const resetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  let datasests = [];

  if (chartData[0].mag_x) {
    datasests.push(
      {
        label: 'Manyetometre X (uT)',
        data: chartData.map((item) => item.mag_x),
        borderColor: CHART_COLORS.blue,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
      }
    )
  }
  if (chartData[0].mag_y) {
    datasests.push(
      {
        label: 'Manyetometre Y (uT)',
        data: chartData.map((item) => item.mag_y),
        borderColor: CHART_COLORS.green,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
      }
    )
  }
  if (chartData[0].mag_z) {
    datasests.push(
      {
        label: 'Manyetometre Z (uT)',
        data: chartData.map((item) => item.mag_z),
        borderColor: CHART_COLORS.yellow,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
      }
    )
  }
  return (
  <>
    {(chartData.length > 0 && (chartData[0].mag_x || chartData[0].mag_y || chartData[0].mag_z )) && (
      <div style={{ marginTop: '30px', marginBottom:'10px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '70vh' }}>
        <button onClick={resetZoom} style={{ border: '2px solid black', padding: '1px', borderRadius: '5px' }}>Zoom Reset</button>
        <LineCharJS
          ref={(ref) => {
            if (ref) {
              chartRef.current = ref;
            }
          }}
          options={
            {
              responsive: true,
              interaction: {
                mode: 'index',
                intersect: false,
              },
              plugins: {
                title: {
                  display: false,
                  text: 'Manyometre Grafiği'
                },
                zoom: {
                  pan: {
                    enabled: true,
                    mode: 'xy',
                  },
                  zoom: {
                    mode: 'xy',
                    wheel: { enabled: true },
                    pinch: { enabled: true },
                  },
                },
              },
              scales: {
                x: {
                  display: true,
                  ticks: {
                    callback: function (value, index, ticks) {
                      const date = this.getLabelForValue(value as number)
                      if (statik == "1") {
                        return moment(date, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY");
                      } else {
                        return moment(date, "DD/MM/YYYY HH:mm:ss").format("HH:mm:ss");
                      }  
                    }
                  }
                },
                y: {
                  type: "linear",
                  display: true,
                  position: 'left',
                },
              }
            }
          }
          data={{
            labels: chartData.map((item) => moment(item.date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")),
            datasets: datasests
          }}
        />
      </div>
    )}
  </>
  )
}

export default MagChartJS;
