/*
Author: Emre EKE
Date: 2023-04-02T13:49:14.921Z
*/

import { FC, useState } from "react"
import { DBToDateText } from "../../../utils/DBToDateText"
import { Button } from "@material-tailwind/react";
import Pagination from "../../../components/Pagination";
import { TablePagePerCount } from "../../../constants/Contants";
import { IAdminDeviceData } from "../../../models/Response";
interface Props {
    deviceDatas: IAdminDeviceData[]
    setDeletedDataId: (num: string) => void
}
const TableComponentAdmin: FC<Props> = (props) => {
    const { deviceDatas, setDeletedDataId } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const start = (currentPage - 1) * TablePagePerCount;
    const end = currentPage * TablePagePerCount;
    return (
        <>
            <div className="overflow-x-auto  mt-5 shadow	">
                <table className="table w-full">
                    <tbody>
                        <tr>
                            <th>Tarih</th>
                            <th>İvme <br></br>X (g)</th>
                            <th>İvme <br></br>Y (g)</th>
                            <th>İvme <br></br>Z (g)</th>
                            <th>Açısal Hız <br></br>X (°/s)</th>
                            <th>Açısal Hız <br></br>Y (°/s)</th>
                            <th>Açısal Hız <br></br>Z (°/s)</th>
                            <th>Manyetometre <br></br>X (uT)</th>
                            <th>Manyetometre <br></br>Y (uT)</th>
                            <th>Manyetometre <br></br>Z (uT)</th>
                            <th>Roll (°)</th>
                            <th>Pitch (°)</th>
                            <th>Yaw (°)</th>
                            <th>İnd.<br></br> Roll (°)</th>
                            <th>İnd.<br></br> Pitch (°)</th>
                            <th>İnd.<br></br> Yaw (°)</th>
                            <th>Sapma<br></br> (mm)</th>
                            <th>İnd.<br></br> Sapma<br></br> (mm)</th>
                            <th>Sıcaklık</th>
                            <th>Batarya <br></br>Volt</th>
                            <th>Veri Gönderim <br></br>Süresi (s)</th>
                            <th>Başarısız<br></br> Deneme</th>
                            <th>Sil</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {deviceDatas.slice(start, end).map((item, index) => {
                            return (
                                <tr key={index}>
                                    <th>{DBToDateText(item.date)}</th>
                                    <th>{item.acc_x}</th>
                                    <th>{item.acc_y}</th>
                                    <th>{item.acc_z}</th>
                                    <th>{item.gyro_x}</th>
                                    <th>{item.gyro_y}</th>
                                    <th>{item.gyro_z}</th>
                                    <th>{item.mag_x}</th>
                                    <th>{item.mag_y}</th>
                                    <th>{item.mag_z}</th>
                                    <th>{item.angle_x}</th>
                                    <th>{item.angle_y}</th>
                                    <th>{item.angle_z}</th>
                                    <th>{item.refined_angle_x}</th>
                                    <th>{item.refined_angle_y}</th>
                                    <th>{item.refined_angle_z}</th>
                                    <th>{item.deflection}</th>
                                    <th>{item.refined_deflection}</th>
                                    <th>{item.temp}</th>
                                    <th>{item.battery_voltage}</th>
                                    <th>{item.millis}</th>
                                    <th>{item.error_count}</th>
                                    <th>
                                        <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                            size="sm"
                                            color="red"
                                            onClick={() => setDeletedDataId(item.id)}
                                        >Sil</Button>
                                    </th>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>
            <Pagination
                dataLength={deviceDatas.length}
                onPageChange={(page) => {
                    setCurrentPage(page);
                }} />
        </>

    )
}
export default TableComponentAdmin;