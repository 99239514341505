import React, { forwardRef, useState } from "react";

export const ExampleCustomInput = forwardRef(
    ({ value, onClick, placeholderText }, ref) => (
        <div
            style={{
                backgroundColor: "#EBECF0",
                borderRadius: "10px",
                cursor: "pointer",
                borderWidth: "1px",
                borderColor: "gray",
                width: "175px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            onClick={onClick}
            ref={ref}
        >
            <span
                style={{
                    fontSize: "14px",
                    color: value ? "#061937" : "#8993A4",
                    userSelect: "none", // Prevent text selection
                }}
            >
                {value || placeholderText}
            </span>
        </div>
    )
);
