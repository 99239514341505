
export const EmailControl = (email?: string) => {
  if (!email) {
    return false;
  }
  if (email.trim() === "") {
    return false
  }
  if (email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase().trimEnd());
  } else {
    return true
  }
}