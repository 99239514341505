import { FC, useState, useRef } from "react"
import { IUserDeviceData } from "../../../models/Response";
import { Line as LineCharJS } from 'react-chartjs-2';
import { Chart as ChartJS } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import CHART_COLORS from "../../../constants/ChartColors";
import zoomPlugin from 'chartjs-plugin-zoom';
import Chart from 'chart.js/auto';
import moment from "moment";
import 'chartjs-plugin-annotation';

// Register the zoom plugin
Chart.register(zoomPlugin);
Chart.register(annotationPlugin);

interface Props {
  data: IUserDeviceData[];
  statik: "0" | "1"
}


interface ChartRefType {
  resetZoom: () => void;
}

const AccChartJS: FC<Props> = ({ data, statik }) => {
  const [chartData] = useState<IUserDeviceData[]>(data);
  const chartRef = useRef<ChartRefType | null>(null); // Specify the type of chartRef
  const chartRef2 = useRef<ChartRefType | null>(null); // Specify the type of chartRef



  const resetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };
  const resetZoom2 = () => {
    if (chartRef2 && chartRef2.current) {
      chartRef2.current.resetZoom();
    }
  };
  let datasests = [];
  if (chartData[0].temp) {
    datasests.push(
      {
        label: 'Sıcaklık (°C)',
        data: chartData.map((item) => item.temp),
        borderColor: CHART_COLORS.red,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y1',
        hidden: true,
      }
    )
  }
  if (chartData[0].acc_x) {
    datasests.push(
      {
        label: 'İvme X(g)',
        data: chartData.map((item) => item.acc_x),
        borderColor: CHART_COLORS.blue,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: false,
      }
    )
  }
  if (chartData[0].acc_y) {
    datasests.push(
      {
        label: 'İvme Y(g)',
        data: chartData.map((item) => item.acc_y),
        borderColor: CHART_COLORS.green,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: false,
      }
    )
  }
  if (chartData[0].acc_z) {
    datasests.push(
      {
        label: 'İvme Z(g)',
        data: chartData.map((item) => item.acc_z),
        borderColor: CHART_COLORS.yellow,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: true,
      }
    )
  }
  return (
    <>
      {(chartData.length > 0 && (chartData[0].temp || chartData[0].acc_x || chartData[0].acc_y || chartData[0].acc_z)) && (
        <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '70vh' }}>
          <button onClick={resetZoom} style={{ border: '2px solid black', padding: '1px', borderRadius: '5px' }}>Zoom Reset</button>
          <LineCharJS
            ref={(ref) => {
              if (ref) {
                chartRef.current = ref;
              }
            }}
            options={
              {
                responsive: true,
                maintainAspectRatio: true,
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
                plugins: {
                  title: {
                    display: false,
                    text: 'İvme Grafiği'
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        var label = context.dataset.label || '';
                        if (label) {
                          label += ': ';
                        }
                        if (context.parsed.y !== null) {
                          if (context.dataset.yAxisID === 'y') {
                            label += Number(context.parsed.y).toFixed(5);
                          } else if (context.dataset.yAxisID === 'y1') {
                            label += Number(context.parsed.y).toFixed(2);
                          }
                        }
                        return label;
                      }
                    }
                  },
                  zoom: {
                    pan: {
                      enabled: true,
                      mode: 'xy',
                    },
                    zoom: {
                      mode: 'xy',
                      wheel: { enabled: true },
                      pinch: { enabled: true },
                    }
                  },
                },
                scales: {
                  x: {
                    display: true,
                    ticks: {
                      callback: function (value, index, ticks) {
                        const date = this.getLabelForValue(value as number)
                        if (statik == "1") {
                          return moment(date, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY");
                        } else {
                          return moment(date, "DD/MM/YYYY HH:mm:ss").format("HH:mm:ss");
                        }
                      }
                    }
                  },
                  y: {
                    type: "linear",
                    display: true,
                    position: 'left',
                  },
                  y1: {
                    type: "linear",
                    display: chartData[0].temp ? true : false,
                    position: 'right',
                    grid: {
                      drawOnChartArea: false
                    },
                  },
                }
              }
            }
            data={{
              labels: chartData.map((item) => moment(item.date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")),
              datasets: datasests
            }}
          />
        </div>
      )}
      {(chartData.length > 0 && (chartData[0].acc_x || chartData[0].acc_y)) && (
        <div style={{ marginTop: '30px', marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '70vh' }}>
          <button onClick={resetZoom2} style={{ border: '2px solid black', padding: '1px', borderRadius: '5px' }}>Zoom Reset</button>
          <ChartJS
            type="scatter"
            ref={(ref) => {
              if (ref) {
                chartRef2.current = ref;
              }
            }}
            data={{
              datasets: [
                {
                  // İlk veri noktasını gösteren scatter grafiği
                  label: 'İlk Veri',
                  data: [{ x: chartData[0].acc_x, y: chartData[0].acc_y }],
                  backgroundColor: 'green',
                  borderColor: 'green',
                  pointRadius: 7,
                  hidden: false,
                },
                {
                  // Son veri noktasını gösteren scatter grafiği
                  label: 'Son Veri',
                  data: [{ x: chartData[chartData.length - 1].acc_x, y: chartData[chartData.length - 1].acc_y }],
                  backgroundColor: 'red',
                  borderColor: 'red',
                  pointRadius: 10,
                  hidden: false,
                },
                {
                  // Diğer tüm veri noktalarını gösteren scatter grafiği
                  label: 'Diğer Veriler',
                  data: chartData.slice(1, chartData.length - 1).map(item => ({ x: item.acc_x, y: item.acc_y })),
                  backgroundColor: 'blue',
                  borderColor: 'blue',
                  pointRadius: 5,
                  hidden: true,
                }
              ]
            }}
            options={{
              responsive: true,
              scales: {
                x: {
                  display: true,
                  title: {
                    display: true,
                    text: 'İvme X (g)'
                  }
                },
                y: {
                  display: true,
                  title: {
                    display: true,
                    text: 'İvme Y (g)'
                  }
                }
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      var label = context.dataset.label || '';
                      if (label) {
                        label += ': ';
                      }
                      if (context.parsed.x !== null) {
                        label += `İvme X (g): ${Number(context.parsed.x).toFixed(5)}`;
                      }
                      if (context.parsed.y !== null) {
                        label += `,İvme Y (g): ${Number(context.parsed.y).toFixed(5)}`;
                      }
                      return label;
                    }
                  }
                },
                annotation: {
                  annotations: {
                    line1: {
                      type: 'line',
                      xMin: chartData[0].acc_x,
                      xMax: chartData[0].acc_x,
                      borderColor: 'green',
                      borderWidth: 2,
                    },
                    line2: {
                      type: 'line',
                      yMin: chartData[0].acc_y,
                      yMax: chartData[0].acc_y,
                      borderColor: 'green',
                      borderWidth: 2,
                    },
                    line3: {
                      type: 'line',
                      xMin: chartData[chartData.length - 1].acc_x,
                      xMax: chartData[chartData.length - 1].acc_x,
                      borderColor: 'red',
                      borderWidth: 2,
                    },
                    line4: {
                      type: 'line',
                      yMin: chartData[chartData.length - 1].acc_y,
                      yMax: chartData[chartData.length - 1].acc_y,
                      borderColor: 'red',
                      borderWidth: 2,
                    },
                  },
                },
                zoom: {
                  pan: {
                    enabled: true,
                    mode: 'xy',
                  },
                  zoom: {
                    mode: 'xy',
                    wheel: { enabled: true },
                    pinch: { enabled: true },
                  }
                },
              }
            }}
          />
        </div>
      )}

    </>
  )
}
export default AccChartJS;
