import { Button, Radio } from "@material-tailwind/react"
import { FC, useState } from "react"
import toast from "react-hot-toast";
import { radioButtons } from "./Constant/ConnectionConstants";
import { IConnections } from "../../../models/AdminPanel";
import API from "../../../API/API";
interface Props {
    selectedConnection: IConnections
    onUpdated: () => void
    onCancel: () => void
}
const ConnectionUpdateModal: FC<any> = (props: Props) => {
    const { selectedConnection } = props;
    const ConnectionUpdate = () => {
        const object = { ...radioValues, id: selectedConnection.id };
        API.getInstance().UpdateConnectionDeviceUser(object)
            .then((response) => {
                toast.success(response.data.message);
                props.onUpdated();
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            })
    }
    const [radioValues, setRadioValues] = useState<IConnections>(
        {
            "temp": selectedConnection?.temp ? selectedConnection.temp : false,
            "acc_x": selectedConnection?.acc_x ? selectedConnection.acc_x : false,
            "acc_y": selectedConnection?.acc_y ? selectedConnection.acc_y : false,
            "acc_z": selectedConnection?.acc_z ? selectedConnection.acc_z : false,
            "gyro_x": selectedConnection?.gyro_x ? selectedConnection.gyro_x : false,
            "gyro_y": selectedConnection?.gyro_y ? selectedConnection.gyro_y : false,
            "gyro_z": selectedConnection?.gyro_z ? selectedConnection.gyro_z : false,
            "mag_x": selectedConnection?.mag_x ? selectedConnection.mag_x : false,
            "mag_y": selectedConnection?.mag_y ? selectedConnection.mag_y : false,
            "mag_z": selectedConnection?.mag_z ? selectedConnection.mag_z : false,
            "angle_x": selectedConnection?.angle_x ? selectedConnection.angle_x : false,
            "angle_y": selectedConnection?.angle_y ? selectedConnection.angle_y : false,
            "angle_z": selectedConnection?.angle_z ? selectedConnection.angle_z : false,
            "refined_angle_x": selectedConnection?.refined_angle_x ? selectedConnection.refined_angle_x : false,
            "refined_angle_y": selectedConnection?.refined_angle_y ? selectedConnection.refined_angle_y : false,
            "refined_angle_z": selectedConnection?.refined_angle_z ? selectedConnection.refined_angle_z : false,
            "deflection": selectedConnection?.deflection ? selectedConnection.deflection : false,
            "refined_deflection": selectedConnection?.refined_deflection ? selectedConnection.refined_deflection : false,
            "millis": selectedConnection?.millis ? selectedConnection.millis : false,
            "battery_voltage": selectedConnection?.battery_voltage ? selectedConnection.battery_voltage : false,
        });
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto">
                    <div className="border-0 rounded-lg shadow-lg relative flex items-center flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 pb-2 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">Güncelle</h3>
                        </div>
                        <div style={{ overflowY: "scroll", height: "auto", padding: 20, maxHeight: "80vh", alignItems: "center", display: "flex", flexDirection: "column", width: "auto" }}>

                            <h5 className="block mb-3 font-sans text-l font-semibold leading-snug tracking-normal text-inherit antialiased">{"Verilere Erişimleri Belirle"}</h5>


                            <div className="card w-72  mt-2 bg-neutral text-neutral-content" style={{ padding: "0px" }}>
                                <div className="card-body items-center text-center">
                                    {
                                        radioButtons.slice(0, 3).map((item, index) => {
                                            const x = Object.entries(selectedConnection).find((i) => i[0] === item.type)
                                            const checked: boolean = x ? x[1] : false;
                                            return (
                                                <>
                                                    <h2 className="card-title">{item.text}</h2>
                                                    <div className="flex flex-row">
<Radio onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}                                                            labelProps={{ className: "text-white" }}
                                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: true }) }}
                                                            id={item.type + "-show"}
                                                            name={`Radio-${item.type}`}
                                                            color="green"
                                                            label={"Görebilir"}
                                                            crossOrigin="anonymous"
                                                            defaultChecked={checked}
                                                        />
<Radio onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}                                                            labelProps={{ className: "text-white" }}
                                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: false }) }}
                                                            id={item.type + "-hide"}
                                                            name={`Radio-${item.type}`}
                                                            color="red"
                                                            label="Göremez"
                                                            crossOrigin="anonymous"
                                                            defaultChecked={!checked} />
                                                    </div>
                                                </>
                                            )
                                        }
                                        )
                                    }
                                </div>
                            </div>

                            <div className="card w-72  mt-2 bg-neutral text-neutral-content" style={{ padding: "0px" }}>
                                <div className="card-body items-center text-center">
                                    {
                                        radioButtons.slice(3, 7).map((item, index) => {
                                            const x = Object.entries(selectedConnection).find((i) => i[0] === item.type)
                                            const checked: boolean = x ? x[1] : false;
                                            return (
                                                <>
                                                    <h2 className="card-title">{item.text}</h2>
                                                    <div className="flex flex-row">
                                                            <Radio onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                                            labelProps={{ className: "text-white" }}
                                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: true }) }}
                                                            id={item.type + "-show"}
                                                            name={`Radio-${item.type}`}
                                                            color="green"
                                                            label={"Görebilir"}
                                                            crossOrigin="anonymous"
                                                            defaultChecked={checked}
                                                        />
                                                            <Radio onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                                            labelProps={{ className: "text-white" }}
                                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: false }) }}
                                                            id={item.type + "-hide"}
                                                            name={`Radio-${item.type}`}
                                                            color="red"
                                                            label="Göremez"
                                                            crossOrigin="anonymous"
                                                            defaultChecked={!checked} />
                                                    </div>
                                                </>
                                            )
                                        }
                                        )
                                    }
                                </div>
                            </div>

                            <div className="card w-72  mt-2 bg-neutral text-neutral-content" style={{ padding: "0px" }}>
                                <div className="card-body items-center text-center">
                                    {
                                        radioButtons.slice(7, 11).map((item, index) => {
                                            const x = Object.entries(selectedConnection).find((i) => i[0] === item.type)
                                            const checked: boolean = x ? x[1] : false;
                                            return (
                                                <>
                                                    <h2 className="card-title">{item.text}</h2>
                                                    <div className="flex flex-row">
                                                        <Radio onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                                            labelProps={{ className: "text-white" }}
                                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: true }) }}
                                                            id={item.type + "-show"}
                                                            name={`Radio-${item.type}`}
                                                            color="green"
                                                            label={"Görebilir"}
                                                            crossOrigin="anonymous"
                                                            defaultChecked={checked}
                                                        />
                                                        <Radio onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                                            labelProps={{ className: "text-white" }}
                                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: false }) }}
                                                            id={item.type + "-hide"}
                                                            name={`Radio-${item.type}`}
                                                            color="red"
                                                            label="Göremez"
                                                            crossOrigin="anonymous"
                                                            defaultChecked={!checked} />
                                                    </div>
                                                </>
                                            )
                                        }
                                        )
                                    }
                                </div>
                            </div>

                            <div className="card w-72  mt-2 bg-neutral text-neutral-content" style={{ padding: "0px" }}>
                                <div className="card-body items-center text-center">
                                    {
                                        radioButtons.slice(11, 14).map((item, index) => {
                                            const x = Object.entries(selectedConnection).find((i) => i[0] === item.type)
                                            const checked: boolean = x ? x[1] : false;
                                            return (
                                                <>
                                                    <h2 className="card-title">{item.text}</h2>
                                                    <div className="flex flex-row">
                                                        <Radio onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                                            labelProps={{ className: "text-white" }}
                                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: true }) }}
                                                            id={item.type + "-show"}
                                                            name={`Radio-${item.type}`}
                                                            color="green"
                                                            label={"Görebilir"}
                                                            crossOrigin="anonymous"
                                                            defaultChecked={checked}
                                                        />
                                                        <Radio onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                                            labelProps={{ className: "text-white" }}
                                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: false }) }}
                                                            id={item.type + "-hide"}
                                                            name={`Radio-${item.type}`}
                                                            color="red"
                                                            label="Göremez"
                                                            crossOrigin="anonymous"
                                                            defaultChecked={!checked} />
                                                    </div>
                                                </>
                                            )
                                        }
                                        )
                                    }
                                </div>
                            </div>

                            <div className="card w-72  mt-2 bg-neutral text-neutral-content" style={{ padding: "0px" }}>
                                <div className="card-body items-center text-center">
                                    {
                                        radioButtons.slice(14, 17).map((item, index) => {
                                            const x = Object.entries(selectedConnection).find((i) => i[0] === item.type)
                                            const checked: boolean = x ? x[1] : false;
                                            return (
                                                <>
                                                    <h2 className="card-title">{item.text}</h2>
                                                    <div className="flex flex-row">
                                                        <Radio onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                                            labelProps={{ className: "text-white" }}
                                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: true }) }}
                                                            id={item.type + "-show"}
                                                            name={`Radio-${item.type}`}
                                                            color="green"
                                                            label={"Görebilir"}
                                                            crossOrigin="anonymous"
                                                            defaultChecked={checked}
                                                        />
                                                        <Radio onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                                            labelProps={{ className: "text-white" }}
                                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: false }) }}
                                                            id={item.type + "-hide"}
                                                            name={`Radio-${item.type}`}
                                                            color="red"
                                                            label="Göremez"
                                                            crossOrigin="anonymous"
                                                            defaultChecked={!checked} />
                                                    </div>
                                                </>
                                            )
                                        }
                                        )
                                    }
                                </div>
                            </div>

                            <div className="card w-72  mt-2 bg-neutral text-neutral-content" style={{ padding: "0px" }}>
                                <div className="card-body items-center text-center">
                                    {
                                        radioButtons.slice(17, 20).map((item, index) => {
                                            const x = Object.entries(selectedConnection).find((i) => i[0] === item.type)
                                            const checked: boolean = x ? x[1] : false;
                                            return (
                                                <>
                                                    <h2 className="card-title">{item.text}</h2>
                                                    <div className="flex flex-row">
                                                        <Radio onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                                            labelProps={{ className: "text-white" }}
                                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: true }) }}
                                                            id={item.type + "-show"}
                                                            name={`Radio-${item.type}`}
                                                            color="green"
                                                            label={"Görebilir"}
                                                            crossOrigin="anonymous"
                                                            defaultChecked={checked}
                                                        />
                                                        <Radio onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                                            labelProps={{ className: "text-white" }}
                                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: false }) }}
                                                            id={item.type + "-hide"}
                                                            name={`Radio-${item.type}`}
                                                            color="red"
                                                            label="Göremez"
                                                            crossOrigin="anonymous"
                                                            defaultChecked={!checked} />
                                                    </div>
                                                </>
                                            )
                                        }
                                        )
                                    }
                                </div>
                            </div>
                            <div className="w-72 mt-5 mb-5 flex flex-row justify-around">
                                <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                    color="red"
                                    onClick={props.onCancel}
                                >İptal</Button>
                                <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                    color="green"
                                    onClick={() => {
                                        ConnectionUpdate();
                                    }}
                                >Güncelle</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}
export default ConnectionUpdateModal;