import { Button, Input, Select, Option, Typography, Progress } from "@material-tailwind/react"
import React, { FC, useRef, useState } from "react"
import { IDevice, IDeviceError } from "../../../../models/AdminPanel";
import API from "../../../../API/API";
import toast from "react-hot-toast";
import { RandomPasswordGenerate } from "../../../../utils/RandomPasswordGenerate";
import axios from "axios";
import { API_URL } from "../../../../common/AppSettings";
import { IPhotoUploadResponse } from "../../../../models/Response";
interface Props {
    selectedDevice: IDevice
    onUpdated: () => void
    onCancel: () => void
}
const DevicesUpdateModal: FC<any> = (props: Props) => {
    const [selectedDevice, setSelectedDevice] = useState<IDevice>(props.selectedDevice)
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const fileUploadRef = useRef<any>();
    const [progressVal, setProgressVal] = useState(100);
    const [file, setFile] = useState();
    const [error, setError] = useState<IDeviceError>({ gyro_esik: false, veri_gonderme_suresi: false, o_sayisi: false, device_seri_no: false, device_name: false, device_password: false, coefficient_x: false, coefficient_y: false, coefficient_z: false });
    const DeviceUpdate = () => {
        if (selectedDevice?.device_seri_no.trim() === "" ||
            selectedDevice?.device_name.trim() === "" ||
            selectedDevice?.device_password.trim() === "" ||
            (!selectedDevice?.coefficient_x_auto && selectedDevice?.coefficient_x === undefined) ||
            (!selectedDevice?.coefficient_y_auto && selectedDevice?.coefficient_y === undefined) ||
            (!selectedDevice?.coefficient_z_auto && selectedDevice?.coefficient_z === undefined)

        ) {
            setError({
                ...error,
                device_seri_no: selectedDevice?.device_seri_no.trim() === "",
                device_name: selectedDevice?.device_name.trim() === "",
                device_password: selectedDevice?.device_password.trim() === "",
                coefficient_x: (!selectedDevice?.coefficient_x_auto && selectedDevice?.coefficient_x === undefined),
                coefficient_y: (!selectedDevice?.coefficient_y_auto && selectedDevice?.coefficient_y === undefined),
                coefficient_z: (!selectedDevice?.coefficient_z_auto && selectedDevice?.coefficient_z === undefined)
            })
        }
        else {
            setError({ ...error, device_seri_no: false, device_name: false, device_password: false, coefficient_x: false, coefficient_y: false, coefficient_z: false })
            if (selectedDevice) {
                API.getInstance().UpdateDevice(selectedDevice)
                    .then((response) => {
                        toast.success(response.data.message);
                        props.onUpdated();
                    })
                    .catch((error) => {
                        toast.error(error.response.data.message);
                    })

            }
        }
    }
    const UpdateOrCreateDeviceFile = async () => {
        setButtonDisabled(true);
        const formData = new FormData();
        formData.append("pdf", file!);
        const URL = API_URL + "/CreateDeviceFileUpload.php";
        axios.post<IPhotoUploadResponse>(URL, formData, {
            headers: {
                'Accept-Language': 'tr',
                'Access-Control-Allow-Origin': "*",
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
            , onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setProgressVal(percent);
            }
        }).then((response) => {
            if (selectedDevice) {
                toast.success(response.data.message)
                setProgressVal(100);
                setSelectedDevice({ ...selectedDevice, device_pdf_url: response.data.url })
            }
            else {
                toast.error("Cihaz seçilmemiştir.");
            }
        }).catch((response) => {
            if (response?.response?.data?.message) {
                toast.error(response?.response?.data?.message)
            }
            else {
                toast.error("Dosya yüklenirken hata oluştu.");
            }
            setProgressVal(100);
        }).finally(() => {
            setButtonDisabled(false);
        })
    }
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-sm">
                    <div className="border-0 rounded-lg shadow-lg relative flex items-center flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 pb-2 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">Güncelle</h3>
                        </div>
                        <div style={{ overflowY: "scroll", height: "auto", padding: 20 }}>
                            <div className="w-72 mt-5 mb-5 ">
                                <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                                    error={error.device_seri_no}
                                    value={selectedDevice.device_seri_no}
                                    crossOrigin="anonymous"
                                    onChange={(event) => {
                                        setSelectedDevice({ ...selectedDevice, device_seri_no: event.target.value })
                                        setError({ ...error, device_seri_no: event.target.value.trim() === "" })
                                    }}
                                    label="Seri No" />
                            </div>
                            <div className="w-72  mb-5 ">
                                <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                                    error={error.device_name}
                                    value={selectedDevice.device_name}
                                    crossOrigin="anonymous"
                                    onChange={(event) => {
                                        setSelectedDevice({ ...selectedDevice, device_name: event.target.value })
                                        setError({ ...error, device_name: event.target.value.trim() === "" })
                                    }}
                                    label="Cihaz Adı" />
                            </div>
                            <div className="w-72 mb-5 flex flex-row ">
                                <div className="w-48">
                                    <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                                        error={error.device_password}
                                        value={selectedDevice.device_password}
                                        crossOrigin="anonymous"
                                        onChange={(event) => {
                                            setSelectedDevice({ ...selectedDevice, device_password: event.target.value })
                                            setError({ ...error, device_password: event.target.value.trim() === "" })
                                        }}
                                        label="Cihaz Şifre"
                                    />
                                </div>
                                <div className="ml-4 w-24">
                                    <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                        onClick={() => {
                                            setSelectedDevice({ ...selectedDevice, device_password: RandomPasswordGenerate() })
                                            setError({ ...error, device_password: false })
                                        }}
                                    >Üret</Button>
                                </div>
                            </div>
                            <div className="mt-4 mb-4 w-72" >
                                <Select placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                    value={selectedDevice.coefficient_x_auto ? "Otomatik" : "Manuel"}
                                    onChange={(item) => {
                                        if (item && item.toString() === "Otomatik") {
                                            setSelectedDevice({ ...selectedDevice, coefficient_x_auto: true, coefficient_x: undefined })
                                        }
                                        else {
                                            setSelectedDevice({ ...selectedDevice, coefficient_x_auto: false })
                                        }
                                    }}
                                    label="Roll Katsayı"
                                >
                                    <Option key={"defX0"} value={"Manuel"} >{`Manuel`}</Option>
                                    <Option key={"defX1"} value={"Otomatik"} >{`Otomatik`}</Option>
                                </Select>
                            </div>
                            {!selectedDevice.coefficient_x_auto &&
                                <div className="mt-1 mb-4 w-72" >
                                    <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                                        error={!selectedDevice.coefficient_x}
                                        type={"number"}
                                        value={selectedDevice.coefficient_x}
                                        crossOrigin="anonymous"
                                        onChange={(event) => {
                                            setSelectedDevice({ ...selectedDevice, coefficient_x: (event.target.value) })
                                        }}
                                        label="Roll Katsayı Giriniz"
                                    />
                                </div>
                            }

                            <div className="mt-4 mb-4 w-72" >
                                <Select placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                    value={selectedDevice.coefficient_y_auto ? "Otomatik" : "Manuel"}
                                    onChange={(item) => {
                                        if (item && item.toString() === "Otomatik") {
                                            setSelectedDevice({ ...selectedDevice, coefficient_y_auto: true, coefficient_y: undefined })
                                        }
                                        else {
                                            setSelectedDevice({ ...selectedDevice, coefficient_y_auto: false })
                                        }
                                    }}
                                    label="Pitch Katsayı"
                                >
                                    <Option key={"defY0"} value={"Manuel"} >{`Manuel`}</Option>
                                    <Option key={"defY1"} value={"Otomatik"} >{`Otomatik`}</Option>
                                </Select>
                            </div>
                            {!selectedDevice.coefficient_y_auto &&
                                <div className="mt-1 mb-4 w-72" >
                                    <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                                        error={!selectedDevice.coefficient_y}
                                        type={"number"}
                                        value={selectedDevice.coefficient_y}
                                        crossOrigin="anonymous"
                                        onChange={(event) => {
                                            setSelectedDevice({ ...selectedDevice, coefficient_y: (event.target.value) })
                                        }}
                                        label="Pitch Katsayı Giriniz"
                                    />
                                </div>
                            }
                            <div className="mt-4 mb-4 w-72" >
                                <Select placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                    value={selectedDevice.coefficient_z_auto ? "Otomatik" : "Manuel"}
                                    onChange={(item) => {
                                        if (item && item.toString() === "Otomatik") {
                                            setSelectedDevice({ ...selectedDevice, coefficient_z_auto: true, coefficient_z: undefined })
                                        }
                                        else {
                                            setSelectedDevice({ ...selectedDevice, coefficient_z_auto: false })
                                        }
                                    }}
                                    label="Yaw Katsayı"
                                >
                                    <Option key={"defZ0"} value={"Manuel"} >{`Manuel`}</Option>
                                    <Option key={"defZ1"} value={"Otomatik"} >{`Otomatik`}</Option>
                                </Select>
                            </div>
                            {!selectedDevice.coefficient_z_auto &&
                                <div className="mt-1 mb-4 w-72" >
                                    <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                                        error={!selectedDevice.coefficient_z}
                                        type={"number"}
                                        value={selectedDevice.coefficient_z}
                                        crossOrigin="anonymous"
                                        onChange={(event) => {
                                            setSelectedDevice({ ...selectedDevice, coefficient_z: (event.target.value) })
                                        }}
                                        label="Yaw Katsayı Giriniz"
                                    />
                                </div>
                            }

                            <div className="mt-4 mb-4 w-72" >
                                <Select placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                    value={selectedDevice.is_statik == "1" ? "Statik" : "Dinamik"}
                                    onChange={(item) => {
                                        if (item && item.toString() === "Statik") {
                                            setSelectedDevice({ ...selectedDevice, is_statik: "1" })
                                        }
                                        else {
                                            setSelectedDevice({ ...selectedDevice, is_statik: "0" })
                                        }
                                    }}
                                    label="Cihaz Türü"
                                >
                                    <Option key={"device_static"} value={"Statik"} >{`Statik`}</Option>
                                    <Option key={"device_dynamic"} value={"Dinamik"} >{`Dinamik`}</Option>
                                </Select>
                            </div>


                            {selectedDevice.device_pdf_url === ""
                                ?
                                <>
                                    <div className="flex flex-row  w-72  justify-between	">
                                        <input
                                            ref={fileUploadRef}
                                            type="file"
                                            placeholder="Pdf yükle"
                                            name="pdf"
                                            accept="application/pdf"
                                            onChange={(e: any) => {
                                                let files = e.target.files[0];
                                                setFile(files);
                                            }} />
                                    </div>
                                    <div className="flex w-72 mt-2 justify-center	">
                                        {
                                            progressVal !== 100 &&
                                            <Progress placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} value={progressVal} />
                                        }
                                    </div>
                                    <div className="flex w-72 mt-2 justify-center">
                                        {(selectedDevice.device_pdf_url === "")
                                            ?
                                            <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="red" variant="paragraph">Pdf dosyası eklenmemiştir.</Typography>
                                            :
                                            <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}  color="green" variant="paragraph">Pdf dosyası eklenmiştir.</Typography>
                                        }
                                    </div>
                                    <div className="flex w-72 mt-1 justify-center">
                                        <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                            style={{
                                            }}
                                            disabled={buttonDisabled}
                                            onClick={() => {
                                                UpdateOrCreateDeviceFile();
                                            }} >Pdf Dosyasını Yükle</Button>
                                    </div>
                                </>

                                :
                                <>
                                    <div className="flex w-72 justify-center">
                                        <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="green" variant="h6">Pdf dosyası vardır.</Typography>
                                    </div>
                                    <div className="flex w-72 mt-1 justify-center">
                                        <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                            style={{
                                            }}
                                            onClick={() => {
                                                setSelectedDevice({ ...selectedDevice, device_pdf_url: "" });
                                            }} >Pdf Dosyasını Sil</Button>
                                    </div>
                                </>
                            }
                            <div className="flex w-72 mt-2 justify-center">
                                <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="red" variant="small">Pdf dosyasında yapılan değişiklikler güncelle'ye basamadığınız sürece değişmez.</Typography>
                            </div>
                            <div className="w-72 mt-5 mb-5 flex flex-row justify-around">
                                <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                    color="red"
                                    onClick={props.onCancel}
                                >İptal</Button>
                                <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                    color="green"
                                    onClick={() => {
                                        DeviceUpdate();
                                    }}
                                >Güncelle</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}
export default DevicesUpdateModal;