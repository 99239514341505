import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

interface Props {
    currentPageName: string
    onSidebarClick: () => void
    isOpen: boolean
    currentPageIndex?: number
}
const Header = (props: Props) => {
    const text = props.currentPageIndex ? props.currentPageIndex.toString()[0] === "2" ? "Seri No : " + props.currentPageName : props.currentPageName : props.currentPageName
    return (
        <header className="bg-white shadow">
            <div className="mx-auto py-6 px-4 sm:px-6 lg:px-8 flex h-16 items-center justify-between">
                <h1 className="text-2xl font-bold tracking-tight text-gray-900">{text}</h1>
                <div className="-mr-2 flex xl:hidden">
                    {/* Mobile menu button */}
                    <button
                        onClick={() => {
                            props.onSidebarClick();
                        }}
                        className="inline-flex items-center justify-center rounded-md bg-blue-gray-800 p-2 text-gray-400 hover:bg-blue-gray-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-gray-800">
                        <span className="sr-only">Open main menu</span>
                        {props.isOpen ? (
                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                    </button>
                </div>
            </div>
        </header>
    )
}
export default Header;