import { CheckCircleIcon, LinkIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../API/API";
import { IForms } from "../../../models/AdminPanel";
import { DBToDateText } from "../../../utils/DBToDateText";
import { Button, Typography } from "@material-tailwind/react";
import DeleteModal from "../../../components/DeleteModal";
import Loading from "../../../components/Loading";

const AboutPages = () => {
    const [forms, setForms] = useState<IForms[]>()

    const [deleteFormId, setDeleteFormId] = useState("");
    const GetContactForms = () => {
        API.getInstance().GetContactForms()
            .then((response) => {
                setForms(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    };
    useEffect(() => {
        GetContactForms();
    }, [])

    const Read = (id: string) => {
        API.getInstance().ReadContactForm(id)
            .then((response) => {
                GetContactForms()
                toast.success(response.data.message)
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }

    const DeleteForm = (id: string) => {
        API.getInstance().DeleteContactForm(id)
            .then((response) => {
                GetContactForms()
                toast.success(response.data.message)
                setDeleteFormId("");
            }).catch((error) => {
                toast.error(error.response.data.message);
                setDeleteFormId("");

            })
    };
    return (
        <div >
            {(!forms) ?
                <Loading />
                :
                (forms.length == 0) ?
                    <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} variant="h5" color="blue-gray" className="mt-5 text-center" >
                        İletişim Formu Bulunmamaktadır.
                    </Typography>
                    :
                    <>
                        <div className="overflow-x-auto mt-5 shadow	 ">
                            <table className="table w-full">
                                <thead>
                                    <tr>
                                        <th>E-posta</th>
                                        <th>Telefon</th>
                                        <th>Konu</th>
                                        <th>Detay</th>
                                        <th>Okundu Mu</th>
                                        <th>Tarih</th>
                                        <th>Oku</th>
                                        <th>Sil</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {/* row 1 */}
                                    {forms?.map((item, index) => {
                                        // const link = item.device_pdf_url === "" ? null : <Link style={{ display: "flex", width: 25 }} target="_blank" to={item.device_pdf_url!}><LinkIcon color="blue" height={25} /></Link>
                                        // const photos = item.device_photos?.map((photoItem) => {

                                        //     return (
                                        //         <Link to={photoItem} target="_blank" style={{
                                        //             cursor: "zoom-in",
                                        //         }}>
                                        //             <img
                                        //                 style={{
                                        //                     margin: 5,
                                        //                     height: 45,
                                        //                     width: 45,
                                        //                     border: "1px solid gray",
                                        //                     borderRadius: 10,
                                        //                 }} src={photoItem} />
                                        //         </Link>
                                        //     )
                                        // })
                                        return (
                                            <tr key={index}>
                                                <th>{item.email}</th>
                                                <th>{item.phone}</th>
                                                <th>{item.subject}</th>
                                                <th>{item.detail}</th>
                                                <th>{item.is_read ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                                <th>{DBToDateText(item.created_date)}</th>
                                                <th><Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} onClick={() => Read(item.id)}>Oku</Button></th>
                                                <th><Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="red" onClick={() => setDeleteFormId(item.id)}>Sil</Button></th>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                        {deleteFormId != "" &&
                            <DeleteModal
                                title={"İletişim formunu sil"}
                                description={"Seçmiş olduğunuz iletişim formu silinsin mi ?"}
                                onCancel={() => setDeleteFormId("")}
                                onOk={() => DeleteForm(deleteFormId)}
                            />
                        }
                    </>
            }

        </div>
    )
}
export default AboutPages;