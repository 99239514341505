import { Button, Input, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../API/API";
import { IUpdatePassword } from "../../models/Response";
interface Props {
    onUpdated: () => void
}

const ProfilePasswordUpdate = (props: Props) => {
    const [userPassword, setUserPassword] = useState({ password: "", newPassword: "", newPasswordSecond: "" });
    const [error, setError] = useState({ password: false, newPassword: false, newPasswordSecond: false, comparePassword: false });
    const UserInfoUpdate = () => {


        if (userPassword.password.trim() === "" || userPassword.newPassword.trim() === "" || userPassword.newPasswordSecond.trim() === "" || userPassword.newPassword !== userPassword.newPasswordSecond) {
            setError({
                password: userPassword.password.trim() === "",
                newPassword: userPassword.newPassword.trim() === "",
                newPasswordSecond: userPassword.newPasswordSecond.trim() === "",
                comparePassword: userPassword.newPassword !== userPassword.newPasswordSecond
            })
        }
        else {
            const object: IUpdatePassword = {
                new_password: userPassword.newPassword,
                old_password: userPassword.password,
            }
            API.getInstance().UpdatePassword(object)
                .then((response) => {
                    toast.success("Şifreniz başarıyla güncellenmiştir.")
                    props.onUpdated();
                }).catch((error) => {
                    toast.error(error.response.data.message);
                })
        }
    }
    return (
        <div className="flex w-full mt-5 flex-col items-center">
            {/* <Avatar src="https://www.material-tailwind.com/img/face-2.jpg" alt="avatar" size="xxl" />
            <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} variant="paragraph">Fotoğraf güncellemek için tıklayınız.</Typography> */}

            <div className=" w-72 mt-5 mb-5 ">
                <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                    error={error.password}
                    crossOrigin="anonymous"
                    value={userPassword.password}
                    onChange={(event) => {
                        setUserPassword({ ...userPassword, password: event.target.value })
                        setError({ ...error, password: event.target.value.trim() === "" })
                    }}
                    label="Mevcut Şifreniz"
                />
            </div>
            <div className=" w-72 mb-5 ">
                <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                    error={error.newPassword}
                    crossOrigin="anonymous"
                    value={userPassword.newPassword}
                    onChange={(event) => {
                        setUserPassword({ ...userPassword, newPassword: event.target.value })
                        setError({ ...error, newPassword: event.target.value.trim() === "" })
                    }}
                    label="Yeni Şifreniz"
                />
            </div>
            <div className=" w-72  ">
                <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                    error={error.newPasswordSecond}
                    crossOrigin="anonymous"
                    value={userPassword.newPasswordSecond}
                    onChange={(event) => {
                        setUserPassword({ ...userPassword, newPasswordSecond: event.target.value })
                        setError({ ...error, newPasswordSecond: event.target.value.trim() === "" })
                    }}
                    label="Yeni Şifreniz(Tekrar)"
                />
            </div>
            {error.comparePassword &&
                <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="red">
                    Yeni şifreleriniz birbirinden farklıdır.
                </Typography>
            }
            <div className="w-72 mt-5 mb-5 flex flex-row justify-around">
                <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                    color="green"
                    onClick={() => {
                        UserInfoUpdate();
                    }}
                >Güncelle</Button>
            </div>
        </div>
    )
}
export default ProfilePasswordUpdate;