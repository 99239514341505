import React, { FC, useEffect, useState } from "react"
import styles from "../../resources/styles/Th.module.css"
import { Button, Input, Textarea, Typography } from "@material-tailwind/react";
import API from "../../API/API";
import toast from "react-hot-toast";
import { EmailControl } from "../../utils/EmailControl";
import { IUserInfo } from "../../models/Response";
import { OnlyNumber } from "../../utils/OnlyNumber";


interface Props {
}
const AboutPage: FC<Props> = (props) => {
    useEffect(() => {
        API.getInstance().GetUserInfo().then((response) => {
            setEmail(response.data.data.email)
        })
    }, []);
    const [subject, setSubject] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [detail, setDetail] = useState("");
    const [error, setError] = useState({
        email: false,
        subject: false,
        detail: false,
        phone: false
    })
    const SendForm = () => {
        if (subject?.trim().length < 1 ||
            !EmailControl(email) ||
            detail?.trim().length < 1 ||
            phone?.trim().length != 11
        ) {
            setError({
                email: !EmailControl(email),
                subject: subject?.trim().length < 1,
                detail: detail?.trim().length < 1,
                phone: phone?.trim().length != 11
            })
        }
        else {
            API.getInstance().CreateContactForm({
                subject,
                email,
                detail,
                phone
            }).then((response) => {
                toast.success(response.data.message);
                setSubject("");
                // setEmail("");
                setDetail("");
                setPhone("");
            }).catch((error) => {
                toast.success(error.response.data.message || "Hata oluştu");
            })
        }
    }
    return (
        <div>
            <div style={{ marginTop: 10, display: "flex", width: "100%", alignItems: "center", justifyContent: "center", flexDirection: "column" }} >

                {/* <input type="text" placeholder="Type here" className="input w-full max-w-xs" /> */}
                <div style={{ width: "100%", maxWidth: 400, }}>
                    <div style={{ marginTop: 10 }}>
                        <Input
                            onPointerEnterCapture={() => { }}
                            onPointerLeaveCapture={() => { }} crossOrigin="anonymous" error={error.subject} value={subject} label="Konu" onChange={(val) => {
                                setError({ ...error, subject: val.target.value?.trim().length < 1, })
                                setSubject(val.target.value);
                            }} />
                        {error.subject &&
                            <Typography placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} color="red" >
                                Konu boş olamaz.
                            </Typography>
                        }
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Input
                            onPointerEnterCapture={() => { }}
                            onPointerLeaveCapture={() => { }} crossOrigin="anonymous" disabled error={error.email} value={email} label="Email" onChange={(val) => {
                                setError({ ...error, email: !EmailControl(val.target.value) })
                                setEmail(val.target.value);
                            }} />
                        {error.email &&
                            <Typography placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} color="red" >
                                Lütfen geçerli bir e-posta adresi giriniz.
                            </Typography>
                        }
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Input
                            onPointerEnterCapture={() => { }}
                            onPointerLeaveCapture={() => { }} crossOrigin="anonymous" maxLength={11} error={error.phone} value={phone} label="Telefon Numarası" onChange={(val) => {
                                setError({ ...error, phone: OnlyNumber(val.target.value?.trim()).length != 11 })
                                setPhone(OnlyNumber(val.target.value));
                            }} />
                        {error.phone &&
                            <Typography placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} color="red" >
                                Telefon numarası formatı 0545XXXXXXX şeklinde olmalıdır.
                            </Typography>
                        }
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Textarea onPointerEnterCapture={() => { }}
                            onPointerLeaveCapture={() => { }} error={error.detail} value={detail} label="Detayı" onChange={(val) => {
                                setError({ ...error, detail: val.target.value?.trim().length < 1, })
                                setDetail(val.target.value);
                            }} />
                        {error.detail &&
                            <Typography placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} color="red" >
                                Detay boş olamaz.
                            </Typography>
                        }
                    </div>
                    <div style={{ display: "flex", width: "100%", marginTop: 10, justifyContent: "center" }}>
                        <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                            onClick={() => {
                                SendForm();
                            }}
                        >Formu Gönder</Button>
                    </div>
                </div>
            </div>
        </div >

    )
}
export default AboutPage;