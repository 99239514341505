import { IPageItem } from "../models/AdminPanel";
import { Button, Typography, } from "@material-tailwind/react";
interface Props {
    item: IPageItem
    setCurrentPage: (item: IPageItem) => void
    currentPage: IPageItem
    subNavItem?: IPageItem
}
const SideBarItem = (props: Props) => {
    const { item, subNavItem, currentPage } = props;
    const color = subNavItem ? subNavItem.index === currentPage.index ? "blue" : "white" : item.index === props.currentPage.index ? "blue" : "white"
    const variant = subNavItem ? subNavItem.index === currentPage.index ? "gradient" : "text" : item.index === props.currentPage.index ? "gradient" : "text"
    return (
        <>
            <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                onClick={() => {
                    subNavItem ? props.setCurrentPage(subNavItem) :
                        props.setCurrentPage(item);
                    subNavItem?.onClick ? subNavItem.onClick() : item.onClick && item.onClick();
                }}
                variant={variant}
                color={color}
                className="flex items-center gap-4 px-4 capitalize"
                fullWidth
            >
                {subNavItem ? subNavItem.icon : item.icon}
                <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                    color="inherit"
                    className="font-medium capitalize"
                >
                    {subNavItem ? subNavItem.name : item.name}
                </Typography>
            </Button>
        </>
    )
}
export default SideBarItem;