import { Button, Input, Select, Option, Typography, Progress } from "@material-tailwind/react"
import React, { FC, useRef, useState } from "react"
import { IDevice, IDeviceError, IUsers } from "../../../../models/AdminPanel";
import API from "../../../../API/API";
import toast from "react-hot-toast";
import { EmailControl } from "../../../../utils/EmailControl";
import moment from "moment";
import { RandomPasswordGenerate } from "../../../../utils/RandomPasswordGenerate";
import { DBToInputText } from "../../../../utils/DBToInputText ";
import { IRole } from "../../../../models/User";
interface Props {
    selectedUser: IUsers
    onUpdated: () => void
    onCancel: () => void
}
const UserUpdateModal: FC<any> = (props: Props) => {
    const [selectedUserId, setSelectedUserId] = useState("")
    const [selectedUser, setSelectedUser] = useState<IUsers>(props.selectedUser)
    const [error, setError] = useState(
        { role: false, email: false, password: false, name: false, surname: false, expired_date: false }
    );
    const UserUpdate = () => {
        if (
            selectedUser?.password.trim() === "" ||
            selectedUser?.name.trim() === "" ||
            selectedUser?.surname.trim() === "" ||
            !EmailControl(selectedUser?.email) ||
            selectedUser?.role?.trim() === "" ||
            selectedUser?.expired_date.trim() === ""
        ) {
            setError(
                {
                    password: selectedUser?.password.trim() === "",
                    name: selectedUser?.name.trim() === "",
                    surname: selectedUser?.surname.trim() === "",
                    email: !EmailControl(selectedUser?.email),
                    role: selectedUser?.role?.trim() === "",
                    expired_date: selectedUser?.expired_date.trim() === "",
                })
        } else {
            let tempUser = selectedUser!;
            tempUser.expired_date = moment(tempUser.expired_date, "YYYY-MM-DD").format("YYYY-MM-DD") + " 23:59:59"
            API.getInstance().UpdateUser(tempUser)
                .then((response) => {
                    toast.success(response.data.message);
                    props.onUpdated();
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                })
        }
    }
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-sm">
                    <div className="border-0 rounded-lg shadow-lg relative flex items-center flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 pb-2 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">Güncelle</h3>
                        </div>
                        <div style={{ overflowY: "scroll", height: "auto", padding: 20 }}>
                            <>
                                <div className="w-72 mt-5 mb-5 ">
                                    <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                                        type={"email"}
                                        error={error.email}
                                        value={selectedUser.email}
                                        crossOrigin="anonymous"
                                        onChange={(event) => {
                                            setSelectedUser({ ...selectedUser, email: event.target.value })
                                            setError({ ...error, email: !EmailControl(event.target.value) })
                                        }}
                                        label="E-posta"
                                    />
                                    {error.email &&
                                        <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="red">
                                            Lütfen geçerli bir e-posta adresi giriniz.
                                        </Typography>
                                    }
                                </div>
                                <div className="flex flex-row  w-72 mb-4">
                                    <div className="w-48">
                                        <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                                            error={error.password}
                                            value={selectedUser.password}
                                            crossOrigin="anonymous"
                                            onChange={(event) => {
                                                setSelectedUser({ ...selectedUser, password: event.target.value })
                                                setError({ ...error, password: event.target.value.trim() === "" })
                                            }}
                                            label="Şifre"
                                        />
                                    </div>
                                    <div className="ml-4 w-24">
                                        <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                            onClick={() => {
                                                setSelectedUser({ ...selectedUser, password: RandomPasswordGenerate() })
                                                setError({ ...error, password: false })
                                            }}
                                        >Üret</Button>
                                    </div>
                                </div>
                                <div className=" w-72 mb-5 ">
                                    <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                                        error={error.name}
                                        crossOrigin="anonymous"
                                        value={selectedUser.name}
                                        onChange={(event) => {
                                            setSelectedUser({ ...selectedUser, name: event.target.value })
                                            setError({ ...error, name: event.target.value.trim() === "" })
                                        }}
                                        label="Ad"
                                    />
                                </div>
                                <div className=" w-72 mb-5 ">
                                    <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                                        error={error.surname}
                                        crossOrigin="anonymous"
                                        value={selectedUser.surname}
                                        onChange={(event) => {
                                            setSelectedUser({ ...selectedUser, surname: event.target.value })
                                            setError({ ...error, surname: event.target.value.trim() === "" })
                                        }}
                                        label="Soyad"
                                    />
                                </div>
                                <div className="mb-5 w-72" >
                                    <Select placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                        error={error.role}
                                        value={selectedUser.role}
                                        onChange={(item) => {
                                            setSelectedUser({ ...selectedUser, role: item?.toString() as IRole })
                                            setError({ ...error, role: item?.toString().trim() === "" })
                                        }}
                                        label={"Rol seçiniz"}
                                    >
                                        <Option value={"USER"} >{`USER`}</Option>
                                        <Option value={"DEMO USER"} >{`DEMO USER`}</Option>
                                    </Select>
                                </div>
                                <div className="mb-5 w-72" >
                                    <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                                        type={"date"}
                                        crossOrigin="anonymous"
                                        error={error.expired_date}
                                        value={DBToInputText(selectedUser.expired_date)}
                                        onChange={(event) => {
                                            setSelectedUser({ ...selectedUser, expired_date: event.target.value })
                                            setError({ ...error, expired_date: event.target.value.trim() === "" })
                                        }}
                                        label="Üyelik Sona Erme Tarihi"
                                        min={moment().format("YYYY-MM-DD")}
                                    />
                                </div>
                                <div className="w-72 mb-5 flex flex-row justify-around">
                                    <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="red" onClick={props.onCancel} >İptal</Button>
                                    <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="green" onClick={UserUpdate} >Güncelle</Button>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}
export default UserUpdateModal;