import { LinkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import API from "../../../API/API";
import { IDevice } from "../../../models/AdminPanel";
import { Button } from "@material-tailwind/react";
import DeleteModal from "../../../components/DeleteModal";
import DevicesUpdateModal from "./components/DevicesUpdateModal";
import Loading from "../../../components/Loading";
import moment from "moment";

const Devices = () => {
    const [devices, setDevices] = useState<IDevice[]>()
    const [updatedDevice, setUpdatedDevice] = useState<IDevice>()
    const [loading, setLoading] = useState(false);
    const GetDevices = () => {
        setLoading(true);
        API.getInstance().GetDevices()
            .then((response) => {
                setDevices(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            }).finally(() => {
                setLoading(false);
            })
    };

    useEffect(() => {
        GetDevices();
    }, [])
    const [deletedId, setDeletedId] = useState("");
    const DeleteDevice = (selectedDeviceId: string) => {

        API.getInstance().DeleteDevice(selectedDeviceId)
            .then((response) => {
                toast.success(response.data.message);
                GetDevices();
                setDeletedId("")
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    };
    return (
        <>
            {
                loading
                    ?
                    <Loading />
                    :
                    <div className="overflow-x-auto mt-5 shadow	 ">
                        <table className="table w-full">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Seri No</th>
                                    <th>Cihaz Adı</th>
                                    <th>Cihaz Şifresi</th>
                                    <th>Türü</th>
                                    <th>Pdf</th>
                                    <th>Fotoğraf</th>
                                    <th>Güncelle</th>
                                    <th>Sil</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* row 1 */}
                                {devices?.map((item, index) => {
                                    const link = item.device_pdf_url === "" ? null : <Link style={{ display: "flex", width: 25 }} target="_blank" to={item.device_pdf_url!}><LinkIcon color="blue" height={25} /></Link>
                                    const photos = item.device_photos?.map((photoItem) => {

                                        return (
                                            <Link to={photoItem} target="_blank" style={{
                                                cursor: "zoom-in",
                                            }}>
                                                <img
                                                    style={{
                                                        margin: 5,
                                                        height: 45,
                                                        width: 45,
                                                        border: "1px solid gray",
                                                        borderRadius: 10,
                                                    }} src={photoItem} />
                                            </Link>
                                        )
                                    })
                                    const status = moment(item.updated_date).isAfter(moment().subtract(item.veri_gonderme_suresi + 600, "second"))
                                    return (
                                        <tr key={index}>
                                            <th>{item.device_id}</th>
                                            <th>{item.device_seri_no}</th>
                                            <th>
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                                                    <div style={{ marginRight: "10px", height: "15px", width: "15px", borderRadius: 100, backgroundColor: status ? "#66bb6a" : "red" }}>
                                                    </div>
                                                    {item.device_name}
                                                </div>
                                            </th>
                                            <th>{item.device_password}</th>
                                            <th>{item.is_statik == "1" ? "Statik" : "Dinamik"}</th>
                                            <th>{link}</th>
                                            <th><div style={{ display: "flex", flexDirection: "row" }}>{photos}</div></th>
                                            <th>
                                                <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} variant="gradient" color="green" onClick={() => setUpdatedDevice(item)}>
                                                    <span>Güncelle</span>
                                                </Button>
                                            </th>
                                            <th>
                                                <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} variant="gradient" color="red" onClick={() => setDeletedId(item.device_id?.toString()!)}>
                                                    <span>Sil</span>
                                                </Button>
                                            </th>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
            }

            {
                deletedId != "" &&
                <DeleteModal
                    title={"Cihazı Sil"}
                    description={"Cihazı ve cihaza ait bütün verileri silmek istiyor musunuz?"}
                    onCancel={() => setDeletedId("")}
                    onOk={() => DeleteDevice(deletedId)} />
            }


            {
                updatedDevice &&
                <DevicesUpdateModal
                    onUpdated={() => {
                        setUpdatedDevice(undefined);
                        GetDevices();
                    }}
                    onCancel={() => setUpdatedDevice(undefined)}
                    selectedDevice={updatedDevice}
                />

            }
        </>
    )
}
export default Devices;