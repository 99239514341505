import { Button, Input, Select, Option, Typography } from "@material-tailwind/react";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../API/API";
import { IUsers } from "../../../models/AdminPanel";
import { EmailControl } from "../../../utils/EmailControl";
import { RandomPasswordGenerate } from "../../../utils/RandomPasswordGenerate";
import { IRole } from "../../../models/User";

interface Props {
    onAdded: () => void
}
const UsersAdd = (props: Props) => {
    const [user, setUser] = useState<IUsers>(
        { user_id: "", role: undefined, password: "", name: "", surname: "", email: "", expired_date: "", created_date: "" }
    );

    const [error, setError] = useState(
        { role: false, email: false, password: false, name: false, surname: false, expired_date: false }
    );

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const AddUser = () => {
        if (
            user.password.trim() === "" ||
            user.name.trim() === "" ||
            user.surname.trim() === "" ||
            !EmailControl(user.email) ||
            user?.role?.trim() === "" ||
            user.expired_date.trim() === ""
        ) {
            setError(
                {
                    password: user.password.trim() === "",
                    name: user.name.trim() === "",
                    surname: user.surname.trim() === "",
                    email: !EmailControl(user.email),
                    role: user?.role?.trim() === "",
                    expired_date: user.expired_date.trim() === "",
                })
        } else {
            setButtonDisabled(true);
            let tempUser = user;
            tempUser.expired_date = moment(tempUser.expired_date, "YYYY-MM-DD").format("YYYY-MM-DD") + " 23:59:59"
            API.getInstance().CreateUser(tempUser)
                .then((response) => {
                    toast.success(response.data.message);
                    props.onAdded();
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                })
                .finally(() => {
                    setButtonDisabled(false);
                })
        }
    }
    return (
        <div className="flex w-full mt-5 flex-col items-center">
            <div className=" w-72 mb-5 ">
                <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                    type={"email"}
                    crossOrigin="anonymous"
                    error={error.email}
                    value={user.email}
                    onChange={(event) => {
                        setUser({ ...user, email: event.target.value })
                        setError({ ...error, email: !EmailControl(event.target.value) })
                    }}
                    label="E-posta"
                />
                {error.email &&
                    <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="red">
                        Lütfen geçerli bir e-posta adresi giriniz.
                    </Typography>
                }
            </div>
            <div className="flex flex-row  w-72 mb-4">
                <div className="w-48">
                    <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                        error={error.password}
                        crossOrigin="anonymous"
                        value={user.password}
                        onChange={(event) => {
                            setUser({ ...user, password: event.target.value })
                            setError({ ...error, password: event.target.value.trim() === "" })
                        }}
                        label="Şifre"
                    />
                </div>
                <div className="ml-4 w-24">
                    <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                        onClick={() => {
                            setUser({ ...user, password: RandomPasswordGenerate() })
                            setError({ ...error, password: false })
                        }}
                    >Üret</Button>
                </div>
            </div>
            <div className=" w-72 mb-5 ">
                <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                    error={error.name}
                    crossOrigin="anonymous"
                    value={user.name}
                    onChange={(event) => {
                        setUser({ ...user, name: event.target.value })
                        setError({ ...error, name: event.target.value.trim() === "" })
                    }}
                    label="Ad"
                />
            </div>
            <div className=" w-72 mb-5 ">
                <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                    error={error.surname}
                    crossOrigin="anonymous"
                    value={user.surname}
                    onChange={(event) => {
                        setUser({ ...user, surname: event.target.value })
                        setError({ ...error, surname: event.target.value.trim() === "" })
                    }}
                    label="Soyad"
                />
            </div>
            <div className="mb-5 w-72" >
                <Select placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                    error={error.role}
                    value={user.role} onChange={(item) => {
                        setUser({ ...user, role: item?.toString() as IRole })
                        setError({ ...error, role: item?.toString().trim() === "" })
                    }}
                    label={"Rol seçiniz"}
                >
                    <Option value={"USER"} >{`USER`}</Option>
                    <Option value={"DEMO USER"} >{`DEMO USER`}</Option>
                </Select>
            </div>
            <div className="mb-5 w-72" >
                <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                    type={"date"}
                    crossOrigin="anonymous"
                    error={error.expired_date}
                    value={user.expired_date}
                    onChange={(event) => {
                        setUser({ ...user, expired_date: event.target.value })
                        setError({ ...error, expired_date: event.target.value.trim() === "" })
                    }}
                    label="Üyelik Sona Erme Tarihi"
                    min={moment().format("YYYY-MM-DD")}
                />
            </div>
            <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                onClick={AddUser}
                disabled={buttonDisabled}
            >Kullanıcı Oluştur</Button>
        </div>
    )
}
export default UsersAdd;