import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import API from '../API/API';
import Loading from '../components/Loading';
import { IRole } from '../models/User';
import logo from '../resources/images/logo.png';
import background from '../resources/images/background.png';

interface Props {
  onLogin: (role: IRole, token: string) => void;
}

const LoginScreen = (props: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);

  const Login = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoginLoading(true);
    API.getInstance()
      .Login(email, password)
      .then((response) => {
        localStorage.setItem('token', response.data.data.token);
        const role = response.data.data.role;
        if (role === 'ADMIN') {
          localStorage.setItem('page', '5');
        } else {
          localStorage.setItem('page', '1');
        }
        toast.success('Giriş başarılı.');
        props.onLogin(response.data.data.role, response.data.data.token);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setLoginLoading(false);
      });
  };

  return (
    <>
      {loginLoading && <Loading />}
      <div
        className="flex flex-col min-h-screen items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
        style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}
      >
        <div className="w-full max-w-md space-y-8 p-6 bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-center">
            <img className="h-36 w-auto" src={logo} alt="Logo" />
          </div>
          <div>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-black-900">
            Yapılar için Otomatik Deformasyon İzleme Merkezi
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={Login}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  E-posta adresi
                </label>
                <input
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value.trim());
                  }}
                  id="email-address"
                  name="email"
                  type="text"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="E-posta Adresi"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Şifre
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Şifre"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Giriş Yap
              </button>
            </div>
          </form>
          <div className="mt-8 text-center">
          <p className="text-sm">
          İletişim: <a href="mailto:r.toprakci@rnteknoloji.com">Recep TOPRAKÇI</a>
          </p>
        </div>
        </div>
        
      </div>
    </>
  );
};

export default LoginScreen;
