import { Button, Input, Progress, Select, Typography, Option } from "@material-tailwind/react";
import axios from "axios";
import { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../API/API";
import { API_URL } from "../../../common/AppSettings";
import { IDevice } from "../../../models/AdminPanel";
import { IPhotoUploadResponse } from "../../../models/Response";
import { RandomPasswordGenerate } from "../../../utils/RandomPasswordGenerate";
interface Props {
    onAdded: () => void
}
const DeviceAdd = (props: Props) => {
    const [device, setDevice] = useState<IDevice>({ gyro_esik: 0.001, veri_gonderme_suresi: 50, o_sayisi: 1, device_seri_no: "", device_name: "", device_password: "", coefficient_x_auto: true, coefficient_y_auto: true, coefficient_z_auto: true, updated_date: "", is_statik: "1" });
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [error, setError] = useState({ deviceSeriNo: false, deviceName: false, devicePassword: false, coefficient_x: false, coefficient_y: false, coefficient_z: false });
    const CreateDevice = () => {
        if (device.device_seri_no.trim() === "" ||
            device.device_name.trim() === "" ||
            device.device_password.trim() === "" ||
            (!device.coefficient_x_auto && device.coefficient_x === undefined) ||
            (!device.coefficient_y_auto && device.coefficient_y === undefined) ||
            (!device.coefficient_z_auto && device.coefficient_z === undefined)

        ) {
            setError({
                deviceSeriNo: device.device_seri_no.trim() === "",
                deviceName: device.device_name.trim() === "",
                devicePassword: device.device_password.trim() === "",
                coefficient_x: (!device.coefficient_x_auto && device.coefficient_x === undefined),
                coefficient_y: (!device.coefficient_y_auto && device.coefficient_y === undefined),
                coefficient_z: (!device.coefficient_z_auto && device.coefficient_z === undefined)
            })
        }
        else {
            setError({ deviceSeriNo: false, deviceName: false, devicePassword: false, coefficient_x: false, coefficient_y: false, coefficient_z: false })
            setButtonDisabled(true);
            API.getInstance().CreateDevice(device)
                .then((response) => {
                    toast.success(response.data.message);
                    props.onAdded();
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                })
                .finally(() => {
                    setButtonDisabled(false);
                })
        }
    }
    const fileUploadRef = useRef<any>();
    const [progressVal, setProgressVal] = useState(100);
    const [file, setFile] = useState();
    const UpdateOrCreateDeviceFile = async () => {
        const formData = new FormData();
        formData.append("pdf", file!);
        const URL = API_URL + "/CreateDeviceFileUpload.php";
        axios.post<IPhotoUploadResponse>(URL, formData, {
            headers: {
                'Accept-Language': 'tr',
                'Access-Control-Allow-Origin': "*",
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
            , onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setProgressVal(percent);
            }
        }).then((response) => {
            toast.success(response.data.message)
            setProgressVal(100);
            setDevice({ ...device, device_pdf_url: response.data.url })
        }).catch((response) => {
            if (response?.response?.data?.message) {
                toast.error(response?.response?.data?.message)
            }
            else {
                toast.error("Dosya yüklenirken hata oluştu.");
            }
            setProgressVal(100);
        })
    }
    return (
        <div className="flex w-full mt-5 flex-col items-center">
            <div className=" w-72 mb-5 ">
                <Input
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                    error={error.deviceSeriNo}
                    crossOrigin="anonymous"
                    value={device.device_seri_no}
                    onChange={(event) => {
                        setDevice({ ...device, device_seri_no: event.target.value })
                        setError({ ...error, deviceSeriNo: event.target.value.trim() === "" })
                    }}
                    label="Seri No" />
            </div>
            <div className=" w-72 mb-5 ">
                <Input
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                    error={error.deviceName}
                    crossOrigin="anonymous"
                    value={device.device_name}
                    onChange={(event) => {
                        setDevice({ ...device, device_name: event.target.value })
                        setError({ ...error, deviceName: event.target.value.trim() === "" })
                    }}
                    label="Cihaz Adı"
                />
            </div>
            <div className="flex flex-row  w-72 mb-4">
                <div className="w-48">
                    <Input
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                        error={error.devicePassword}
                        crossOrigin="anonymous"
                        value={device.device_password}
                        onChange={(event) => {
                            setDevice({ ...device, device_password: event.target.value })
                            setError({ ...error, devicePassword: event.target.value.trim() === "" })
                        }}
                        label="Cihaz Şifre"
                    />
                </div>
                <div className="ml-4 w-24">
                    <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                        onClick={() => {

                            setDevice({ ...device, device_password: RandomPasswordGenerate() })
                            setError({ ...error, devicePassword: false })
                        }}
                    >Üret</Button>
                </div>
            </div>
            <div className="mt-4 mb-4 w-72" >
                <Select placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                    value={device.coefficient_x_auto ? "Otomatik" : "Manuel"}
                    onChange={(item) => {
                        if (item && item.toString() === "Otomatik") {
                            setDevice({ ...device, coefficient_x_auto: true, coefficient_x: undefined })
                        }
                        else {
                            setDevice({ ...device, coefficient_x_auto: false })
                        }
                    }}
                    label="Roll Katsayı"
                >
                    <Option key={"defX0"} value={"Manuel"} >{`Manuel`}</Option>
                    <Option key={"defX1"} value={"Otomatik"} >{`Otomatik`}</Option>
                </Select>
            </div>
            {!device.coefficient_x_auto &&
                <div className="mt-1 mb-4 w-72" >
                    <Input
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                        crossOrigin="anonymous"
                        error={!device.coefficient_x}
                        type={"number"}
                        value={device.coefficient_x}
                        onChange={(event) => {
                            setDevice({ ...device, coefficient_x: (event.target.value) })
                        }}
                        label="Roll Katsayı Giriniz"
                    />
                </div>
            }

            <div className="mt-4 mb-4 w-72" >
                <Select placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                    value={device.coefficient_y_auto ? "Otomatik" : "Manuel"}
                    onChange={(item) => {
                        if (item && item.toString() === "Otomatik") {
                            setDevice({ ...device, coefficient_y_auto: true, coefficient_y: undefined })
                        }
                        else {
                            setDevice({ ...device, coefficient_y_auto: false })
                        }
                    }}
                    label="Pitch Katsayı"
                >
                    <Option key={"defY0"} value={"Manuel"} >{`Manuel`}</Option>
                    <Option key={"defY1"} value={"Otomatik"} >{`Otomatik`}</Option>
                </Select>
            </div>
            {!device.coefficient_y_auto &&
                <div className="mt-1 mb-4 w-72" >
                    <Input
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                        error={!device.coefficient_y}
                        crossOrigin="anonymous"
                        type={"number"}
                        value={device.coefficient_y}
                        onChange={(event) => {
                            setDevice({ ...device, coefficient_y: (event.target.value) })
                        }}
                        label="Pitch Katsayı Giriniz"
                    />
                </div>
            }
            <div className="mt-4 mb-4 w-72" >
                <Select placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                    value={device.coefficient_z_auto ? "Otomatik" : "Manuel"}
                    onChange={(item) => {
                        if (item && item.toString() === "Otomatik") {
                            setDevice({ ...device, coefficient_z_auto: true, coefficient_z: undefined })
                        }
                        else {
                            setDevice({ ...device, coefficient_z_auto: false })
                        }
                    }}
                    label="Yaw Katsayı"
                >
                    <Option key={"defZ0"} value={"Manuel"} >{`Manuel`}</Option>
                    <Option key={"defZ1"} value={"Otomatik"} >{`Otomatik`}</Option>
                </Select>
            </div>

            {!device.coefficient_z_auto &&
                <div className="mt-1 mb-4 w-72" >
                    <Input
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                        error={!device.coefficient_z}
                        crossOrigin="anonymous"
                        type={"number"}
                        value={device.coefficient_z}
                        onChange={(event) => {
                            setDevice({ ...device, coefficient_z: (event.target.value) })
                        }}
                        label="Yaw Katsayı Giriniz"
                    />
                </div>
            }

            <div className="mt-4 mb-4 w-72" >
                <Select placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                    value={device.is_statik == "1" ? "Statik" : "Dinamik"}
                    onChange={(item) => {
                        if (item && item.toString() === "Statik") {
                            setDevice({ ...device, is_statik: "1" })
                        }
                        else {
                            setDevice({ ...device, is_statik: "0" })
                        }
                    }}
                    label="Cihaz Türü"
                >
                    <Option key={"device_static"} value={"Statik"} >{`Statik`}</Option>
                    <Option key={"device_dynamic"} value={"Dinamik"} >{`Dinamik`}</Option>
                </Select>
            </div>
            <div className="flex flex-row  w-72  justify-between	">
                <input
                    ref={fileUploadRef}
                    type="file"
                    placeholder="Pdf yükle"
                    name="pdf"
                    accept="application/pdf"
                    onChange={(e: any) => {
                        let files = e.target.files[0];
                        setFile(files);
                    }} />
            </div>
            <div className="flex w-72 mt-2 justify-center	">
                {
                    progressVal !== 100 &&
                    <Progress placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} value={progressVal} />
                }
            </div>
            <div className="flex w-72 mt-2 justify-center">
                {(device.device_pdf_url === "" || !device.device_pdf_url)
                    ?
                    <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="red" variant="paragraph">Pdf dosyası eklenmemiştir.</Typography>
                    :
                    <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="green" variant="paragraph">Pdf dosyası eklenmiştir.</Typography>
                }
            </div>
            <div className="flex w-72 mt-1 justify-center">
                <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                    style={{
                    }}
                    disabled={buttonDisabled}
                    onClick={() => {
                        UpdateOrCreateDeviceFile();
                    }} >Pdf Dosyasını Yükle</Button>
            </div>
            <div className="flex  w-72 mt-4 justify-center	">
                <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                    color="green"
                    disabled={buttonDisabled}
                    onClick={() => {
                        CreateDevice();
                    }} >Cihaz Oluştur</Button>
            </div>
        </div>
    )
}
export default DeviceAdd;