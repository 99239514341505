import { LinkIcon } from "@heroicons/react/24/solid";
import { Button, Select, Typography, Option } from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import API from "../../../API/API";
import Loading from "../../../components/Loading";
import { IDevice } from "../../../models/AdminPanel";
import { IUserDeviceData, IUserDeviceInfo } from "../../../models/Response";
import { DBToDateText } from "../../../utils/DBToDateText";
import TableComponent from "./TableComponent";
import styles from "../../../resources/styles/Th.module.css";
import DeleteModal from "../../../components/DeleteModal";
import SettingsModalUser from "../components/SettingsModalUser";
import { jsPDF } from "jspdf";
import { PDFViewer, Page, Document, Text, View } from "@react-pdf/renderer";
import PdfModal from "../../../components/PdfModal";
import ExcelExportNew from "../../AdminPanel/DeviceDatas/ExcelExportNew";
import moment from "moment";
import AccChartJS from "../chart-js/AccChartJS";
import AngleChartJS from "../chart-js/AngleChartJS";
import DefAngleChartJS from "../chart-js/DefAngleChartJS";
import GyroChartJS from "../chart-js/GyroChartJS";
import MagChartJS from "../chart-js/MagChartJS";
import MillisChartJS from "../chart-js/MillisChartJS";
import BatteryChartJS from "../chart-js/BatteryChartJS";
import CustomDate from "../../../components/CustomDate";




interface Props {
    item: IDevice
}

let GetUserDeviceDatasAbort: AbortController = new AbortController();
let GetUserDeviceInfoAbort: AbortController = new AbortController();


const Device = (props: Props) => {
    const { item } = props;
    // Props olarak sadece device_id yollansın.
    // device_id den kullanıcıya aitse bilgileri çekelim.

    const [device, setDevice] = useState<IUserDeviceInfo>();
    const [deviceDatas, setDeviceDatas] = useState<IUserDeviceData[]>();


    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const startDateFormatted = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss") : undefined;
    const endDateFormatted = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : undefined;


    const GetDeviceInfo = () => {
        if (GetUserDeviceInfoAbort) {
            GetUserDeviceInfoAbort.abort();
        }
        GetUserDeviceInfoAbort = new AbortController();
        API.getInstance().GetUserDeviceInfo(GetUserDeviceInfoAbort, item.device_id?.toString()!, timeRangeSelect.value, startDateFormatted, endDateFormatted)
            .then((response) => {
                setDevice(response.data.data);
                setDeviceHeight(response.data.data.device_height);
                setDeviceStatic(response.data.data.is_statik);
            }).catch((error) => {
                error?.response?.data?.message && toast.error(error.response.data.message);
            })
    }
    useEffect(() => {
        setDevice(undefined);
        GetDeviceInfo();
        GetUserDeviceDatas();
        setActiveTagIndex(-1);
    }, [item])

    const [deviceHeight, setDeviceHeight] = useState<number>(0);
    const [deviceStatic, setDeviceStatic] = useState<"0" | "1">("0");
    const [deletedDataId, setDeletedDataId] = useState("");
    const [loading, setLoading] = useState(false);
    const [deleteAllModal, setDeleteAllModal] = useState(false);



    const GetUserDeviceDatas = () => {
        if (GetUserDeviceDatasAbort) {
            GetUserDeviceDatasAbort.abort();
        }
        GetUserDeviceDatasAbort = new AbortController();
        setLoading(true);
        API.getInstance().GetUserDeviceDatas(GetUserDeviceDatasAbort, item.device_id?.toString()!, timeRangeSelect.value, startDateFormatted, endDateFormatted)
            .then((response) => {
                setDeviceDatas(response.data.data)
            }).catch((error) => {
                error?.response?.data?.message && toast.error(error.response.data.message);
            }).finally(() => {
                setLoading(false);
            })
    }

    const DeleteDeviceData = () => {
        API.getInstance().DeleteUserDeviceData(deletedDataId)
            .then((response) => {
                toast.success(response.data.message);
                GetUserDeviceDatas();
            }).catch((error) => {
                error?.response?.data?.message && toast.error(error.response.data.message);
            }).finally(() => {
                setDeletedDataId("");
            })
    }
    const Refresh = () => {
        GetUserDeviceDatas();
        GetDeviceInfo();
    }
    const DeleteUserDeviceDatas = () => {
        API.getInstance().DeleteUserDeviceDatas(item.device_id?.toString()!)
            .then((response) => {
                toast.success(response.data.message);
                GetUserDeviceDatas();
                setDeleteAllModal(false);
            }).catch((error) => {
                error?.response?.data?.message && toast.error(error.response.data.message);
            }).finally(() => {
                setDeletedDataId("");
            })
    }
    const [activeTabIndex, setActiveTagIndex] = useState(-1);
    const [settingsModalShow, setSettingsModalShow] = useState(false);


    // Tablo otomatik yükseklik 
    const calculateHeight1 = () => {
        const h1 = document.getElementById("table1")?.offsetHeight
        const h2 = document.getElementById("table2")?.offsetHeight
        if (h1 && h2) {
            return Math.max(h1, h2);
        }
        return undefined;
    }
    const calculateHeight2 = () => {
        const h3 = document.getElementById("table3")?.offsetHeight
        const h4 = document.getElementById("table4")?.offsetHeight
        if (h3 && h4) {
            return Math.max(h3, h4);
        }
        return undefined;
    }

    const [tableHeight1, setTableHeight1] = useState<number | undefined>(calculateHeight1())
    const [tableHeight2, setTableHeight2] = useState<number | undefined>(calculateHeight2())

    useEffect(() => {
        setTimeout(() => {
            setTableHeight1(calculateHeight1())
            setTableHeight2(calculateHeight2())
        }, 200);
    }, [device])
    // Tablo otomatik yükseklik 


    // const pdfRef = useRef(null);

    // const Download = () => {
    //     const doc = new jsPDF();
    //     const table = document.getElementById('download_table')!;
    //     doc.html(table, {
    //         callback: function () {
    //             doc.save('table.pdf');
    //         }
    //     });
    // };


    const tableData = [
        { id: 1, name: 'John Doe', age: 25 },
        { id: 2, name: 'Jane Smith', age: 30 },
        { id: 3, name: 'Bob Johnson', age: 35 }
    ];

    const [pdfModal, setPdfModal] = useState(false);
    const status = moment(item.updated_date).isAfter(moment().subtract((item.veri_gonderme_suresi * 2) + 600, "second"))

    const timeRange = [
        {
            "label": "Son 10 Dakika",
            "value": "-10 minutes"
        },
        {
            "label": "Son 30 Dakika",
            "value": "-30 minutes"
        },
        {
            "label": "Son 1 Saat",
            "value": "-1 hour"
        },
        {
            "label": "Son 3 Saat",
            "value": "-3 hour"
        },
        {
            "label": "Son 6 Saat",
            "value": "-6 hour"
        },
        {
            "label": "Son 12 Saat",
            "value": "-12 hour"
        },
        {
            "label": "Son 1 Gün",
            "value": "-1 day"
        },
        {
            "label": "Son 2 Gün",
            "value": "-2 day"
        },
        {
            "label": "Özel Tarih Aralığı",
            "value": "custom"
        },
        // {
        //     "label": "Tüm Zamanlar",
        //     "value": "all"
        // },

    ]
    const [timeRangeSelect, setTimeRange] = useState<{ "label": string, "value": string }>(timeRange[0]);

    useEffect(() => {
        if (timeRangeSelect.value !== "custom" && device) {
            GetUserDeviceDatas();
            GetDeviceInfo();
        }
    }, [timeRangeSelect]);


    useEffect(() => {
        if (startDate) {
            setEndDate(null);
        }
    }, [startDate]);




    return (
        <>
            {
                deviceDatas &&
                <>
                    {device &&
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 " style={{ justifyItems: "center" }} >

                            <div className="overflow-x-auto  mt-5 mb-5 shadow border-gray-300" style={{ width: "350px" }}>
                                <table id="table1" className="table" style={{
                                    width: "350px",
                                    height: tableHeight1 && tableHeight1
                                }}>
                                    <tbody >
                                        <tr>
                                            <th className={styles.th} >Cihaz Id</th>
                                            <td className={styles.td}>{`${device?.device_id} (${device.is_statik == "1" ? "Statik" : "Dinamik"})`}</td>
                                        </tr>
                                        <tr >
                                            <th className={styles.th}>Seri No</th>
                                            <td className={styles.td}>{device.device_seri_no}</td>
                                        </tr>

                                        <tr >
                                            <th className={styles.th}>Cihaz İsmi</th>
                                            <td className={styles.td}>{device.device_name}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-x-auto  mt-5 mb-5 shadow border-gray-300" style={{ width: "350px" }}>
                                <table id="table2" className="table" style={{
                                    width: "350px",
                                    height: tableHeight1 && tableHeight1
                                }}>
                                    <tbody>
                                        <tr >
                                            <th className={styles.th}>Cihaz Açıklama</th>
                                            <td className={styles.td}><p className={styles.td}
                                                style={{
                                                    whiteSpace: "normal",
                                                }}>{device.device_description == "" ? "Açıklama girilmemiştir." : device.device_description}</p></td>
                                        </tr>
                                        <tr >
                                            <th className={styles.th}>Cihaz Fotoğrafı</th>
                                            <td className={styles.td}>{(device.device_photos && device.device_photos.length > 0) ?
                                                <div className="flex flex-row">
                                                    {device?.device_photos?.map((item) => {
                                                        return (
                                                            <a href={item} target="_blank">
                                                                <img
                                                                    onClick={() => {
                                                                    }}
                                                                    style={{
                                                                        margin: 5,
                                                                        height: 25,
                                                                        width: 25,
                                                                        border: "1px solid gray",
                                                                        borderRadius: 5,
                                                                        cursor: "zoom-in",
                                                                    }} src={item} />
                                                            </a>
                                                        )
                                                    })}
                                                </div>
                                                :
                                                "Fotoğraf Yüklenmemiştir."
                                            }</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-x-auto  mt-5 mb-5 shadow border-gray-300" style={{ width: "350px" }}>
                                <table id="table3" className="table" style={{
                                    width: "350px",
                                    height: tableHeight2 && tableHeight2
                                }}>
                                    <tbody>
                                        <tr >
                                            <th className={styles.th}>Cihaz Yönetimi</th>
                                            <td className={styles.td}>
                                                {device.relay_error &&
                                                    <p className={styles.th}>
                                                        {"Kalibrasyon Tarihi"}
                                                        <br></br>
                                                        {DBToDateText(device.relay_error_date)}
                                                    </p>
                                                }
                                                {device.sensor_error &&
                                                    <p className={styles.th}>
                                                        {"Sensör Okuma Hatası"}
                                                        <br></br>
                                                        {DBToDateText(device.sensor_error_date)}
                                                    </p>
                                                }
                                                {(!device.relay_error && !device.sensor_error) && "Kayıt Yok"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={styles.th}>Cihaz Yükseklik</th>
                                            <td className={styles.td}>{device.device_height} mm </td>
                                        </tr>
                                        {/*
                                        <tr >
                                            <th className={styles.th}>İndirgenmiş Sapma</th>
                                            <td className={styles.td}>{device.refined_deflection} mm</td>
                                        </tr>
                                            */}
                                        <tr >
                                            <th className={styles.th}>Kalibrasyon Belgesi</th>
                                            <td className={styles.td}>
                                                {
                                                    device.device_pdf_url === "" ?
                                                        "Bulunmamaktadır"
                                                        :
                                                        <Link className="flex flex-row items-center  " target="_blank" to={device?.device_pdf_url}>
                                                            <LinkIcon color="#2196f3" height={25} />
                                                            <Typography placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} className="ml-1" color="blue" variant="h6">Görüntüle</Typography>
                                                        </Link>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            {(deviceDatas.length > 0 || device.first_date || device.last_date) &&
                                <div className="overflow-x-auto  mt-5 mb-5 shadow border-gray-300" style={{ width: "350px" }}>
                                    <table id="table4" className="table" style={{
                                        width: "350px",
                                        height: tableHeight2 && tableHeight2
                                    }}>
                                        <tbody>
                                            {/*
                                            {device.battery_voltage &&
                                                <tr >
                                                    <th className={styles.th}>Batarya Volt <br></br>(6V-8V)</th>
                                                    <td className={styles.td}>{`${device.battery_voltage} V (${BatteryVoltageCalc(device.battery_voltage)})`}</td>
                                                </tr>
                                            */}
                                            {
                                                deviceDatas.length > 0 &&
                                                <tr >
                                                    <th className={styles.th}>Veri Sayısı</th>
                                                    <td className={styles.td}>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                            {`${deviceDatas.length}   `}
                                                            {status != undefined && <span style={{ width: "80%", textAlign: "center", fontWeight: "bold", color: status ? "#66bb6a" : "red" }}>{status ? "ONLINE" : "OFFLINE"}</span>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            {device.first_date &&
                                                <tr >
                                                    <th className={styles.th}>İlk Veri Tarihi</th>
                                                    <td className={styles.td}>{DBToDateText(device.first_date)}</td>
                                                </tr>
                                            }
                                            {device.last_date &&
                                                <tr >
                                                    <th className={styles.th}>Son Veri Tarihi</th>
                                                    <td className={styles.td}>{DBToDateText(device.last_date)}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }

                        </div>
                    }
                </>
            }
            {device ?
                <>
                    {
                        device.is_statik == "0" &&
                        <div className="w-full flex justify-center flex-col ">

                            <div className="w-full mt-2  flex justify-center">
                                <div className="w-64">
                                    <Select
                                        onPointerEnterCapture={() => { }}
                                        onPointerLeaveCapture={() => { }}
                                        placeholder=""
                                        value={timeRangeSelect?.value}
                                        onChange={(item) => {
                                            setTimeRange(timeRange.find((i) => i.value === item?.toString())!);
                                        }}
                                        label={"Tarih Aralığı"}
                                    >
                                        {
                                            timeRange.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item.value} >{item.label}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                            {
                                timeRangeSelect?.value === "custom" ?
                                    <div className="flex flex-col justify-center mb-6 w-full">
                                        <div className="flex flex-row justify-center ">
                                            <CustomDate
                                                maxDate={moment().toDate()}
                                                date={startDate}
                                                setDate={setStartDate}
                                                title="Başlangıç Tarihi"
                                            />
                                            <CustomDate
                                                minDate={startDate}
                                                date={endDate}
                                                maxDate={moment().isAfter(moment(startDate).add(2, "days")) ? moment(startDate).add(2, "days").toDate() : moment().toDate()}
                                                setDate={setEndDate}
                                                title="Bitiş Tarihi"
                                            />
                                        </div>
                                        <div className="flex mt-3 justify-center">
                                            <Button
                                                onPointerEnterCapture={() => { }}
                                                onPointerLeaveCapture={() => { }}
                                                placeholder=""
                                                disabled={startDate === null || endDate === null}
                                                onClick={() => {
                                                    GetUserDeviceDatas();
                                                    GetDeviceInfo();
                                                }}
                                            >Bilgilerini Getir</Button>
                                        </div>


                                    </div>
                                    :
                                    <div className="mb-4" />
                            }
                        </div>
                    }
                    <div className="flex justify-center ">

                        <div className="w-fit flex mx-3 mt-3 sm:mt-0 justify-center sm:justify-end tabs " style={{ maxWidth: 1400 }}>

                            <div className=" flex mx-3 mt-3 sm:mt-0 justify-center sm:justify-end ">
                                <ExcelExportNew seriNo={device.device_seri_no} data={deviceDatas} dataList={undefined} />
                            </div>
                            {/* 
                            <div className=" flex mx-3 mt-3 sm:mt-0 justify-center sm:justify-end ">
                                <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                    className="mr-3"
                                    color="orange"
                                    onClick={() => setPdfModal(true)}
                                >React-pdf</Button>
                            </div> 
                            */}
                            <div className=" flex mx-3 mt-3 sm:mt-0 justify-center sm:justify-end ">
                                <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                    className="mr-3"
                                    color="orange"
                                    onClick={() => setSettingsModalShow(true)}
                                >Ayarlar</Button>
                            </div>
                            <div className="flex mx-3 mt-3 sm:mt-0 justify-center sm:justify-end ">
                                <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                    color="red"
                                    onClick={() => setDeleteAllModal(true)}
                                >Tüm Verileri Sil</Button>
                            </div>
                            <div className="flex mx-3 mt-3 sm:mt-0 justify-center sm:justify-end ">
                                <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                    color="green"
                                    onClick={() => Refresh()}
                                >Verileri Yenile</Button>
                            </div>

                        </div>

                    </div>
                    {/* <PDFDownloadLink document={ */}
                    {pdfModal &&
                        <PdfModal title={"Pdf Olarak Görüntüle"} content={
                            <>
                                <PDFViewer style={{ width: "full", height: '600px' }}>
                                    <Document>
                                        <Page size="A4">
                                            <View
                                                style={{
                                                    alignItems: "center"
                                                }}
                                            >

                                                <View
                                                    style={{
                                                        width: 250,
                                                        borderWidth: 1,

                                                    }}>
                                                    <View
                                                        style={{
                                                            flexDirection: "row"
                                                        }}
                                                    >
                                                        <View style={{ borderWidth: 1, width: 130 }}>
                                                            <Text>{`Cihaz Id:`}</Text>
                                                        </View>
                                                        <View style={{ borderWidth: 1, width: 150 }}>
                                                            <Text>{`${device?.device_id}`}</Text>
                                                        </View>
                                                    </View>
                                                    <Text>{`Seri No :${device?.device_seri_no}`}</Text>
                                                    <Text>{`Cihaz İsmi:${device?.device_name}`}</Text>
                                                </View>
                                            </View>


                                            <View style={{ flexDirection: "row" }}>
                                                <Text>ID</Text>
                                                <Text>Name</Text>
                                                <Text>Age</Text>
                                            </View>
                                            {tableData.map(row => (
                                                <View key={row.id}>
                                                    <Text>{row.id}</Text>
                                                    <Text>{row.name}</Text>
                                                    <Text>{row.age}</Text>
                                                </View>
                                            ))}
                                        </Page>
                                    </Document>
                                </PDFViewer>
                                <div className=" flex mx-3 mt-3 sm:mt-0 justify-center sm:justify-end ">
                                    <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                        className="mr-3"
                                        color="orange"
                                        onClick={() => setPdfModal(false)}
                                    >Kapat</Button>
                                </div>
                            </>


                        } />

                    }


                    {/* }
                        fileName="table.pdf">
                        {({ blob, url, loading, error }) => (
                            loading ? 'Yükleniyor...' : 'PDF İndir'
                        )}
                    </PDFDownloadLink> */}
                    {/* <div className="flex w-full flex-row justify-end px-5">
                        {
                            (!loading && deviceDatas && deviceDatas.length > 0) &&
                            <div className="mx-3">
                                <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                    className="mr-3"
                                    color="orange"
                                    onClick={() => setSettingsModalShow(true)}
                                >Ayarlar</Button>
                                <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                    color="red"
                                    onClick={() => setDeleteAllModal(true)}
                                >Tüm Verileri Sil</Button>
                            </div>
                        }
                        <div className="mx-3">
                            <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                color="green"
                                onClick={() => Refresh()}
                            >Verileri Yenile</Button>
                        </div>
                    </div> */}
                    {
                        (settingsModalShow) &&
                        <SettingsModalUser
                            device={{
                                device_id: device.device_id,
                                device_height: device.device_height,
                                device_name: device.device_name,
                                device_seri_no: device.device_seri_no,
                                device_description: device.device_description,
                                device_photos: device.device_photos,
                                veri_gonderme_suresi: device.veri_gonderme_suresi,
                                gyro_esik: device.gyro_esik,
                                o_sayisi: device.o_sayisi,
                                is_statik: device.is_statik,
                                dynamic_type: device.dynamic_type,
                            }}
                            onCancel={() =>
                                setSettingsModalShow(false)
                            }
                            onUpdated={() => {
                                GetDeviceInfo();
                                setSettingsModalShow(false)
                                GetUserDeviceDatas();
                            }}
                        />
                    }
                    <>
                        {loading ?
                            <Loading /> :
                            deviceDatas &&
                                deviceDatas.length !== 0 ?
                                <>
                                    <div className="flex justify-center">
                                        <div className="w-fit	justify-center mt-3 tabs tabs-boxed ">
                                            {(deviceDatas[0].acc_x || deviceDatas[0].acc_y || deviceDatas[0].acc_z) && <a onClick={() => setActiveTagIndex(0)} className={`tab tab-lg ${activeTabIndex === 0 ? "tab-active" : ""}`}>İvme Grafiği</a>}
                                            {(deviceDatas[0].angle_x || deviceDatas[0].angle_y || deviceDatas[0].angle_z || deviceDatas[0].deflection || (deviceDatas[0].temp)) && <a onClick={() => setActiveTagIndex(1)} className={`tab tab-lg ${activeTabIndex === 1 ? "tab-active" : ""}`}>Açı Grafiği </a>}
                                            {(deviceDatas[0].refined_angle_x || deviceDatas[0].refined_angle_y || deviceDatas[0].refined_angle_z || deviceDatas[0].refined_deflection) && <a onClick={() => setActiveTagIndex(2)} className={`tab tab-lg ${activeTabIndex === 2 ? "tab-active" : ""}`}>İnd. Açı Grafiği </a>}
                                            {(deviceDatas[0].gyro_x || deviceDatas[0].gyro_y || deviceDatas[0].gyro_z) && <a onClick={() => setActiveTagIndex(3)} className={`tab tab-lg ${activeTabIndex === 3 ? "tab-active" : ""}`}>A.Hız Grafiği</a>}
                                            {(deviceDatas[0].mag_x || deviceDatas[0].mag_y || deviceDatas[0].mag_z) && <a onClick={() => setActiveTagIndex(4)} className={`tab tab-lg ${activeTabIndex === 4 ? "tab-active" : ""}`}>Mag Grafiği</a>}
                                            {deviceDatas[0].millis && <a onClick={() => setActiveTagIndex(5)} className={`tab tab-lg ${activeTabIndex === 5 ? "tab-active" : ""}`}>VGS Grafiği </a>}
                                            {deviceDatas[0].battery_voltage && <a onClick={() => setActiveTagIndex(6)} className={`tab tab-lg ${activeTabIndex === 6 ? "tab-active" : ""}`}>Batarya Grafiği </a>}
                                            <a onClick={() => setActiveTagIndex(7)} className={`tab tab-lg ${activeTabIndex === 7 ? "tab-active" : ""}`}>Data</a>
                                        </div>
                                    </div>
                                    {activeTabIndex === 0 ?
                                        // <AccChart data={deviceDatas} />
                                        <AccChartJS data={deviceDatas} statik={deviceStatic} />
                                        : activeTabIndex === 1 ?
                                            // <AngleChart data={deviceDatas} height={deviceHeight} />
                                            <AngleChartJS data={deviceDatas} height={deviceHeight} statik={deviceStatic} />
                                            :
                                            activeTabIndex === 2 ?
                                                // <DefAngleChart data={deviceDatas} height={deviceHeight} />
                                                <DefAngleChartJS data={deviceDatas} height={deviceHeight} statik={deviceStatic} />
                                                :
                                                activeTabIndex === 3 ?
                                                    // <GyroChartJS data={deviceDatas} />
                                                    <GyroChartJS data={deviceDatas} statik={deviceStatic} />
                                                    :
                                                    activeTabIndex === 4 ?
                                                        // <MagChart data={deviceDatas} />
                                                        <MagChartJS data={deviceDatas} statik={deviceStatic} />
                                                        :
                                                        activeTabIndex === 5 ?
                                                            // <MillisChart data={deviceDatas} />
                                                            <MillisChartJS data={deviceDatas} statik={deviceStatic} />
                                                            :
                                                            activeTabIndex === 6 ?
                                                                // <BatteryChart data={deviceDatas} height={deviceHeight} />
                                                                <BatteryChartJS data={deviceDatas} height={deviceHeight} statik={deviceStatic} />
                                                                :
                                                                activeTabIndex === 7 ?
                                                                    <TableComponent setDeletedDataId={setDeletedDataId} deviceDatas={deviceDatas} />
                                                                    :
                                                                    null
                                    }
                                </>
                                :
                                <Typography placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} className="text-center mt-5" color="red" variant="h3">Cihaza ait veri bulunmamaktadır.</Typography>
                        }
                    </>
                    {
                        deletedDataId !== "" &&
                        <DeleteModal
                            title={"Veriyi Sil"}
                            description={"Seçmiş olduğunuz veri silinecektir. Onaylıyor musunuz?"}
                            onCancel={() =>
                                setDeletedDataId("")
                            }
                            onOk={() => {
                                DeleteDeviceData();
                            }} />
                    }
                    {
                        deleteAllModal &&
                        <DeleteModal
                            title={"Verileri Sil"}
                            description={"Cihazınıza ait tüm veriler silinecektir. Onaylıyor musunuz?"}
                            onCancel={() =>
                                setDeleteAllModal(false)
                            }
                            onOk={() => {
                                DeleteUserDeviceDatas();
                            }
                            } />
                    }
                </>
                :
                <Loading />
            }
        </>
    )
}
export default Device;
