import { useState } from "react";
import { TablePagePerCount } from "../constants/Contants";

interface Props {
    dataLength: number;
    onPageChange: (pageNumber: number) => void;
    currentPage?: number;
}
const Pagination: React.FC<Props> = (props) => {
    const maxPage = Math.ceil(props.dataLength / TablePagePerCount);
    const [currentPage, setCurrentPage] = useState<number>(props.currentPage ? props.currentPage : 1);
    const decrease = () => {
        if (currentPage > 1) {
            props.onPageChange(currentPage - 1);
            setCurrentPage((prevPage) => prevPage - 1)
        }
    }
    const increase = () => {
        if (currentPage < maxPage) {
            props.onPageChange(currentPage + 1);
            setCurrentPage((prevPage) => prevPage + 1)
        }
    }
    return (
        <div className="flex btn-group justify-center mt-2">
            <div className="btn-group">
                <button className="btn" onClick={() => decrease()}>«</button>
                <button className="btn" >Sayfa {currentPage}/{maxPage}</button>
                <button className="btn" onClick={() => increase()} >»</button>
            </div>
        </div>
    );
};

export default Pagination;
