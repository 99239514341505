import React, { FC, useEffect, useState } from "react"
import styles from "../../resources/styles/Th.module.css"
import { IDevice } from "../../models/AdminPanel"
import { Alert } from "@material-tailwind/react"
import API from "../../API/API"
import { IUserInfo } from "../../models/Response"
import { DBToDateText } from "../../utils/DBToDateText"
import moment from "moment"
interface Props {
    devices: IDevice[],
    onClickDevice: (seri_no: string) => void
}
const UserHomePage: FC<Props> = (props) => {
    const { devices } = props

    const [width, setWidth] = useState(350);
    const calculateWidth = () => {
        const widthList = []
        for (let index = 0; index < devices.length; index++) {
            const itemWidth = document.getElementById("table" + index)?.offsetWidth;
            widthList.push(itemWidth ? itemWidth : 0);
        }
        return Math.max(...widthList);
    };

    useEffect(() => {
        if (devices) {
            setTimeout(() => {
                setWidth(calculateWidth())
            }, 200);
        }
    }, [devices])
    const [userInfo, setUserInfo] = useState<IUserInfo>();
    useEffect(() => {
        API.getInstance().GetUserInfo().then((response) => {
            setUserInfo(response.data.data);
        })
    }, []);

    return (
        <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }} >
                {userInfo &&
                    <>
                        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-black-900">
                            Bilgiler
                        </h2>
                        <div
                            className="overflow-x-auto  mt-2 mb-2 shadow border-gray-300"
                            style={{ width: width }}
                        >
                            <table id={"table-info"} className="table" style={{ width: width }}>
                                <tbody>
                                    <tr>
                                        <th className={styles.th} style={{ paddingRight: 5, textAlign: "right" }}>E-Posta Adresi</th>
                                        <td className={styles.td} style={{ paddingLeft: 5, textAlign: "left" }}>{userInfo.email}</td>
                                    </tr>
                                    <tr>
                                        <th className={styles.th} style={{ paddingRight: 5, textAlign: "right" }}>Üyelik Bitiş Tarihi</th>
                                        <td className={styles.td} style={{ paddingLeft: 5, textAlign: "left" }}>{DBToDateText(userInfo.expired_date ? userInfo.expired_date : "")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                }
                <h2 className="mt-4 text-center text-3xl font-bold tracking-tight text-black-900">
                    Cihazlarım
                </h2>


                {devices.map((item, index) => {
                    const status = moment(item.updated_date).isAfter(moment().subtract(item.veri_gonderme_suresi + 600, "second"))
                    return (
                        <div
                            onClick={() => props.onClickDevice(item.device_seri_no)}
                            className="overflow-x-auto  mt-2 mb-2 shadow border-gray-300"
                            style={{ width: width, cursor: "pointer" }}
                        >
                            <table id={"table" + index} className="table" style={{ width: width }}>
                                <tbody>
                                    <tr>
                                        <th className={styles.th} style={{ paddingRight: 5, textAlign: "right" }}>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <div style={{ marginRight: "10px", height: "15px", width: "15px", borderRadius: 100, backgroundColor: status ? "#66bb6a" : "red" }}>
                                                </div>
                                                Seri No
                                            </div>
                                        </th>
                                        <td className={styles.td} style={{ paddingLeft: 5, textAlign: "left" }}>{item.device_seri_no}</td>
                                        <th className={styles.th} style={{ paddingRight: 5, textAlign: "right" }} >Cihaz İsmi</th>
                                        <td className={styles.td} style={{ paddingLeft: 5, textAlign: "left" }}>{item.device_name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )
                })}

                {/* <div className="overflow-x-auto  mt-5 mb-5 shadow border-gray-300" style={{ width: "350px" }}>
                    <table id="table1" className="table" style={{
                        width: "350px",
                    }}>
                        <tbody >
                            <tr  >
                                <th className={styles.th} >Cihaz Id</th>
                                <td className={styles.td}>{"device?.device_id"}</td>
                            </tr>
                            <tr >
                                <th className={styles.th}>Seri No</th>
                                <td className={styles.td}>{"device.device_seri_no"}</td>
                            </tr>

                            <tr >
                                <th className={styles.th}>Cihaz İsmi</th>
                                <td className={styles.td}>{"device.device_name"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}
            </div>
        </div>

    )
}
export default UserHomePage;