
import axios from 'axios';
import { API_URL } from '../common/AppSettings';
import { IConnections, IDevice, IUsers } from '../models/AdminPanel';
import { IContactFormsResponse, IGetAdminDeviceDatas, IGetAdminDeviceInfo, IGetConnectionsResponse, IGetDevicesResponse, IGetUserDeviceDatas, IGetUserDeviceInfo, IGetUserInfoResponse, IGetUsersResponse, ILoginResponse, IResponse, ITokenResponse, IUpdateDeviceUser, IUpdatePassword, IUserInfo } from '../models/Response';
export default class API {
  static Instance: API;
  api = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'tr',
      'Access-Control-Allow-Origin': "*",
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    },
  });
  setAuthorizationToken(token: string) {
    this.api.defaults.headers.Authorization = "Bearer " + token;
  }
  setDeleteAuthorizationToken() {
    delete this.api.defaults.headers.Authorization;
  }
  //https://stackoverflow.com/questions/45578844/how-to-set-header-and-options-in-axios
  static getInstance() {
    if (!API.Instance) {
      API.Instance = new API();
    }
    return API.Instance;
  }
  getTokenHeader() {
    return {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    }
  }
  Login(email: string, password: string) {
    return (this.api.post<ILoginResponse>("Login.php", { email: email, password: password }));
  }
  TokenCheck(token: string) {
    return (this.api.get<ITokenResponse>("TokenCheck.php"));
  }
  CreateDevice(device: IDevice) {
    return (this.api.post<IResponse>("CreateDevice.php", device));
  }
  GetDevices() {
    return (this.api.get<IGetDevicesResponse>("GetDevices.php"));
  }
  DeleteDevice(device_id: string) {
    return (this.api.post<IResponse>("DeleteDevice.php", { device_id: device_id }));
  }
  UpdateDevice(selectedDevice: IDevice) {
    return (this.api.post<IResponse>("UpdateDevice.php", selectedDevice));
  }
  GetUsers() {
    return (this.api.get<IGetUsersResponse>("GetUsers.php"));
  }
  CreateUser(user: IUsers) {
    return (this.api.post<IResponse>("CreateUser.php", user));
  }
  DeleteUser(user_id: string) {
    return (this.api.post<IResponse>("DeleteUser.php", { user_id: user_id }));
  }
  UpdateUser(selectedUser: IUsers) {
    return (this.api.post<IResponse>("UpdateUser.php", selectedUser));
  }
  CreateConnectionDeviceUser(object: IConnections) {
    return (this.api.post<IResponse>("CreateConnectionDeviceUser.php", object));
  }
  GetConnectionDeviceUser() {
    return (this.api.get<IGetConnectionsResponse>("GetConnectionDeviceUser.php"));
  }
  DeleteConnectionDeviceUser(id: string) {
    return (this.api.post<IResponse>("DeleteConnectionDeviceUser.php", { id: id }));
  }
  UpdateConnectionDeviceUser(object: IConnections) {
    return (this.api.post<IResponse>("UpdateConnectionDeviceUser.php", object));
  }

  GetUserInfo() {
    return (this.api.get<IGetUserInfoResponse>("GetUserInfo.php"));
  }
  UpdateProfile(user: IUserInfo) {
    return (this.api.post<IResponse>("UpdateProfile.php", user));
  }
  UpdatePassword(object: IUpdatePassword) {
    return (this.api.post<IResponse>("UpdatePassword.php", object));
  }

  // GetAllDeviceData() {
  //   return (this.api.get<IGetAllDeviceDataResponse>("GetAllDeviceData.php"));
  // }

  // DeleteAllDeviceData() {
  //   return (this.api.get("DeleteAllDeviceData.php"));
  // }

  GetUserDevices() {
    return (this.api.get<IGetDevicesResponse>("GetUserDevices.php"));
  }
  GetUserDeviceInfo(controller: AbortController, device_id: string, timeType?: string, startDate?: string, endDate?: string) {
    const { signal } = controller;
    return (this.api.post<IGetUserDeviceInfo>("GetUserDeviceInfo.php", { device_id, timeType, startDate, endDate }, { signal }));
  }

  GetUserDeviceDatas(controller: AbortController, device_id: string, timeType?: string, startDate?: string, endDate?: string) {
    const { signal } = controller;
    return (this.api.post<IGetUserDeviceDatas>("GetUserDeviceDatas.php", { device_id, timeType, startDate, endDate }, { signal }));
  }

  DeleteUserDeviceData(id: string) {
    return (this.api.post<IResponse>("DeleteUserDeviceData.php", { id: id }));
  }

  DeleteUserDeviceDatas(device_id: string) {
    return (this.api.post<IResponse>("DeleteUserDeviceDatas.php", { device_id: device_id }));
  }


  GetAdminDeviceInfo(device_id: string, timeType?: string, startDate?: string, endDate?: string) {
    return (this.api.post<IGetAdminDeviceInfo>("GetAdminDeviceInfo.php", { device_id, timeType, startDate, endDate }));
  }
  GetAdminDeviceDatas(device_id: string, timeType?: string, startDate?: string, endDate?: string) {
    return (this.api.post<IGetAdminDeviceDatas>("GetAdminDeviceDatas.php", { device_id, timeType, startDate, endDate }));
  }

  DeleteAdminDeviceData(id: string) {
    return (this.api.post<IResponse>("DeleteAdminDeviceData.php", { id }));
  }
  DeleteAdminDeviceDatas(device_id: string) {
    return (this.api.post<IResponse>("DeleteAdminDeviceDatas.php", { device_id }));
  }

  UpdateDeviceUser(params: IUpdateDeviceUser) {
    return (this.api.post<IGetUserDeviceInfo>("UpdateDeviceUser.php", params));
  }

  CreateContactForm(params: {
    email: string
    subject: string
    detail: string
    phone: string
  }) {
    return (this.api.post<IResponse>("CreateContactForm.php", params));
  }
  GetContactForms() {
    return (this.api.get<IContactFormsResponse>("GetContactForms.php"));
  }

  ReadContactForm(id: string) {
    return (this.api.post<IResponse>("ReadContactForm.php", { id }));
  }

  DeleteContactForm(id: string) {
    return (this.api.post<IResponse>("DeleteContactForm.php", { id }));
  }
}
