import { Avatar, Button, Input, Progress, Typography } from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../API/API";
import { IPhotoUploadResponse, IUserInfo } from "../../models/Response";
import { EmailControl } from "../../utils/EmailControl";
import { API_URL } from '../../common/AppSettings';
import axios from "axios";

interface Props {
    onUpdated: () => void
}

const ProfileEdit = (props: Props) => {
    const [progressVal, setProgressVal] = useState(100);
    const [userInfo, setUserInfo] = useState<IUserInfo>({ name: "", surname: "", email: "" });
    const GetUserInfo = () => {
        API.getInstance().GetUserInfo()
            .then((response) => {
                setUserInfo(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }
    useEffect(() => {
        GetUserInfo();
    }, [])

    const [error, setError] = useState({ name: false, surname: false, email: false });
    const UserInfoUpdate = () => {
        if (userInfo.name.trim() === "" || userInfo.surname.trim() === "" || !EmailControl(userInfo.email)) {
            setError({ name: userInfo.name.trim() === "", surname: userInfo.surname.trim() === "", email: !EmailControl(userInfo.email) })
        }
        else {
            API.getInstance().UpdateProfile(userInfo)
                .then((response) => {
                    toast.success("Profil başarıyla güncellenmiştir.")
                    props.onUpdated();
                }).catch((error) => {
                    toast.error(error.response.data.message);
                })
        }
    }


    const fileUploadRef = useRef<any>();

    const UploadOrCreateUserPhoto = async (image: any) => {
        const formData = new FormData();
        formData.append("image", image);
        const URL = API_URL + "/UpdateOrCreateUserPhoto.php";
        axios.post<IPhotoUploadResponse>(URL, formData, {
            headers: {
                'Accept-Language': 'tr',
                'Access-Control-Allow-Origin': "*",
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
            , onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                // `${loaded} bytes of ${total} bytes. ${percent}%`
                setProgressVal(percent);
            }
        }).then((response) => {
            toast.success(response.data.message)
            setUserInfo({ ...userInfo, profile_photo_url: response.data.url })
            setProgressVal(100);
        }).catch((response) => {
            if (response?.response?.data?.message) {
                toast.error(response?.response?.data?.message)
            }
            else {
                toast.error("Fotoğraf yüklenirken hata oluştu.");
            }
            setProgressVal(100);
        })
    }

    return (
        <div className="flex w-full mt-5 flex-col items-center">
            <Avatar onPointerEnterCapture={() => { }}
                onPointerLeaveCapture={() => { }} placeholder="" onClick={() => {
                    fileUploadRef.current.click();
                }} src={userInfo.profile_photo_url ? userInfo.profile_photo_url : "https://www.material-tailwind.com/img/face-2.jpg"} alt="avatar" size="xxl" />
            <Typography placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} variant="paragraph">Fotoğraf güncellemek için tıklayınız.</Typography>
            {
                progressVal !== 100 &&
                <div className=" w-72 ">
                    <Progress placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} value={progressVal} />
                </div>
            }
            <input
                style={{ display: "none" }}
                ref={fileUploadRef}
                type="file"
                placeholder="Resim yükle"
                name="image"
                accept="image/png, image/jpeg, image/jpg, image/gif"
                onChange={(e: any) => {
                    let files = e.target.files[0];
                    UploadOrCreateUserPhoto(files);
                }} />
            <div className=" w-72 mt-5 mb-5 ">
                <Input
                    onPointerEnterCapture={() => { }}
                    onPointerLeaveCapture={() => { }}
                    error={error.name}
                    crossOrigin="anonymous"
                    value={userInfo.name}
                    onChange={(event) => {
                        setUserInfo({ ...userInfo, name: event.target.value })
                        setError({ ...error, name: event.target.value.trim() === "" })
                    }}
                    label="Ad"
                />
            </div>
            <div className=" w-72 mb-5 ">
                <Input
                    onPointerEnterCapture={() => { }}
                    onPointerLeaveCapture={() => { }}
                    error={error.surname}
                    crossOrigin="anonymous"
                    value={userInfo.surname}
                    onChange={(event) => {
                        setUserInfo({ ...userInfo, surname: event.target.value })
                        setError({ ...error, surname: event.target.value.trim() === "" })
                    }}
                    label="Soyad"
                />
            </div>
            <div className="w-72 mb-5">
                <Input
                    onPointerEnterCapture={() => { }}
                    onPointerLeaveCapture={() => { }}
                    type="email"
                    crossOrigin="anonymous"
                    readOnly // Okunabilir (değiştirilemez) hale getirir
                    value={userInfo.email}
                    label="E-posta"
                />
            </div>
            <div className="w-72 mb-5 flex flex-row justify-around">
                <Button onPointerEnterCapture={() => { }}
                    onPointerLeaveCapture={() => { }} placeholder=""
                    color="green"
                    onClick={() => {
                        UserInfoUpdate();
                    }}
                >Güncelle</Button>
            </div>
        </div>
    )
}
export default ProfileEdit;