/*
Author: Emre EKE
Date: 2023-04-02T13:49:14.921Z
*/

import React, { FC, useState } from "react"
import { DBToDateText } from "../../../utils/DBToDateText"
import { IUserDeviceData } from "../../../models/Response";
import { Button } from "@material-tailwind/react";
import Pagination from "../../../components/Pagination";
import { TablePagePerCount } from "../../../constants/Contants";
interface Props {
    deviceDatas: IUserDeviceData[]
    setDeletedDataId: (num: string) => void
}
const TableComponent: FC<Props> = (props) => {
    const { deviceDatas, setDeletedDataId } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const start = (currentPage - 1) * TablePagePerCount;
    const end = currentPage * TablePagePerCount;
    return (
        <>
            <div className="overflow-x-auto mt-5 shadow	 ">
                <table id="download_table" className="table w-full">
                    <tbody>
                        <tr>
                            <th>Tarih</th>
                            {deviceDatas[0].acc_x && <th>İvme X (g)</th>}
                            {deviceDatas[0].acc_y && <th>İvme Y (g)</th>}
                            {deviceDatas[0].acc_z && <th>İvme Z (g)</th>}
                            {deviceDatas[0].gyro_x && <th>Açısal Hız X (°/s)</th>}
                            {deviceDatas[0].gyro_y && <th>Açısal Hız Y (°/s)</th>}
                            {deviceDatas[0].gyro_z && <th>Açısal Hız Z (°/s)</th>}
                            {deviceDatas[0].mag_x && <th>Manyetometre X (uT)</th>}
                            {deviceDatas[0].mag_y && <th>Manyetometre Y (uT)</th>}
                            {deviceDatas[0].mag_z && <th>Manyetometre Z (uT)</th>}
                            {deviceDatas[0].angle_x && <th>Roll (°)</th>}
                            {deviceDatas[0].angle_y && <th>Pitch (°)</th>}
                            {deviceDatas[0].angle_z && <th>Yaw (°)</th>}
                            {deviceDatas[0].refined_angle_x && <th>İnd. Roll (°)</th>}
                            {deviceDatas[0].refined_angle_y && <th>İnd. Pitch (°)</th>}
                            {deviceDatas[0].refined_angle_z && <th>İnd. Yaw (°)</th>}
                            {deviceDatas[0].deflection !== undefined && <th>Sapma (mm)</th>}
                            {deviceDatas[0].refined_deflection !== undefined && <th>İnd.Sapma (mm)</th>}
                            {deviceDatas[0].temp && <th>Sıcaklık (°C)</th>}
                            {deviceDatas[0].battery_voltage && <th>Batarya Volt (V)</th>}
                            {deviceDatas[0].millis && <th>Veri Gönderim Süresi (s)</th>}
                            <th>Sil</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {deviceDatas.slice(start, end).map((item, index) => {
                            return (
                                <tr key={index}>
                                    <th>{DBToDateText(item.date)}</th>
                                    {item.acc_x && <th>{item.acc_x}</th>}
                                    {item.acc_y && <th>{item.acc_y}</th>}
                                    {item.acc_z && <th>{item.acc_z}</th>}
                                    {item.gyro_x && <th>{item.gyro_x}</th>}
                                    {item.gyro_y && <th>{item.gyro_y}</th>}
                                    {item.gyro_z && <th>{item.gyro_z}</th>}
                                    {item.mag_x && <th>{item.mag_x}</th>}
                                    {item.mag_y && <th>{item.mag_y}</th>}
                                    {item.mag_z && <th>{item.mag_z}</th>}
                                    {item.angle_x && <th>{item.angle_x}</th>}
                                    {item.angle_y && <th>{item.angle_y}</th>}
                                    {item.angle_z && <th>{item.angle_z}</th>}
                                    {item.refined_angle_x && <th>{item.refined_angle_x}</th>}
                                    {item.refined_angle_y && <th>{item.refined_angle_y}</th>}
                                    {item.refined_angle_z && <th>{item.refined_angle_z}</th>}
                                    {item.deflection !== undefined && <th>{item.deflection}</th>}
                                    {item.refined_deflection !== undefined && <th>{item.refined_deflection}</th>}
                                    {item.temp && <th>{item.temp}</th>}
                                    {item.battery_voltage && <th>{item.battery_voltage}</th>}
                                    {item.millis && <th>{item.millis}</th>}
                                    <th>
                                        <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                            size="sm"
                                            color="red"
                                            onClick={() => setDeletedDataId(item.id)}
                                        >Sil</Button>
                                    </th>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>
            <Pagination
                dataLength={deviceDatas.length}
                onPageChange={(page) => {
                    setCurrentPage(page);
                }} />
        </>

    )
}
export default TableComponent;