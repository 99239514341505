import { Link } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { IconButton, Typography } from "@material-tailwind/react";
import { IPageItem, IPages } from "../models/AdminPanel";
import SideBarItem from "./SidebarItem";
import SideBarItemCollapse from "./SidebarItemCollapse";

interface Props {
    title: string
    isOpen: boolean
    onClick: () => void
    pages: IPages[],
    currentPage: IPageItem
    setCurrentPage: (page: IPageItem) => void
}
export function AdminSidebar(props: Props) {
    const { isOpen, onClick, pages } = props;
    return (
        <aside
            className={`overflow-y-auto custom-scroll bg-gradient-to-br from-blue-gray-800 to-blue-gray-900 ${isOpen ? "translate-x-0" : "-translate-x-80"} fixed inset-0 z-50 my-4 ml-4 h-[calc(100vh-32px)] w-72 rounded-xl transition-transform duration-300 xl:translate-x-0`}
        >
            <div
                className={"relative border-b border-white/20 "}
            >
                <Link to="/" className="flex items-center gap-4 py-6 px-8">
                <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} variant="h6" color={"white"} >
                    {props.title}
                </Typography>
                </Link>
                <IconButton
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                    variant="text"
                    color="blue"
                    size="md"
                    ripple={false}
                    className="absolute right-3 top-3 grid rounded-br-none rounded-tl-none xl:hidden"
                    onClick={onClick}
                    placeholder=""
                >
                    <XMarkIcon strokeWidth={2.5} className="h-5 w-5 text-white" />
                </IconButton>
            </div>
            <div className="m-4">
                {/* {routes.map(({ layout, title, pages }, key) => ( */}
                <ul className="mb-4 flex flex-col gap-1">
                    {/* {title && (
                            <li className="mx-3.5 mt-4 mb-2">
                                <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                    variant="small"
                                    color={"white"}
                                    className="font-black uppercase opacity-75"
                                >
                                    {title}
                                </Typography>
                            </li>
                        )} */}
                    {pages.map((item) => {
                        return (
                            <div key={item.index}>
                                {
                                    item.subNav
                                        ?
                                        <SideBarItemCollapse
                                            item={item}
                                            currentPage={props.currentPage}
                                            setCurrentPage={props.setCurrentPage}
                                        />
                                        :
                                        <SideBarItem
                                            item={item}
                                            currentPage={props.currentPage}
                                            setCurrentPage={props.setCurrentPage}
                                        />
                                }
                            </div>
                        )
                    })}
                </ul>
                {/* ))} */}
            </div>
        </aside >
    );
}
export default AdminSidebar;
