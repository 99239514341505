import { IRole } from "../models/User";
import AdminPanel from "./AdminPanel/AdminPanel";
import UserPanel from "./UserPanel/UserPanel";
interface Props {
    role: IRole
    onLogOut: () => void
}
const Dashboard = (props: Props) => {
    return (
        <>
            {props.role === "ADMIN" &&
                <AdminPanel onLogOut={props.onLogOut} />
            }
            {props.role === "USER" &&
                <UserPanel onLogOut={props.onLogOut} />
            }
            {props.role === "DEMO USER" &&
                <UserPanel onLogOut={props.onLogOut} />
            }
        </>
    )
}
export default Dashboard;