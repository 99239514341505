import { Avatar, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../API/API";
import Loading from "../../components/Loading";
import { IUserInfo } from "../../models/Response";
import { DBToDateText } from "../../utils/DBToDateText";

const Profile = () => {
    const [userInfo, setUserInfo] = useState<IUserInfo>()
    useEffect(() => {
        API.getInstance().GetUserInfo()
            .then((response) => {
                setUserInfo(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }, [])
    return (
        <>
            {
                userInfo ?
                    <div className="flex items-center flex-col mt-5">
                        <Avatar onPointerLeaveCapture={() => {}} onPointerEnterCapture={() => {}} placeholder="" src={userInfo.profile_photo_url ? userInfo.profile_photo_url : "https://www.material-tailwind.com/img/face-2.jpg"} alt="avatar" size="xxl" />
                        <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="red" variant="h4">{userInfo?.role}</Typography>
                        <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} variant="h3">{`${userInfo.name} ${userInfo.surname}`}</Typography>
                        <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} variant="h4">{userInfo.email}</Typography>
                        {(userInfo.role === "USER" && userInfo.expired_date) &&
                            <div className="mt-3 flex items-center flex-col">
                                <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} variant="h4">Üyelik Bitiş Tarihi</Typography>
                                <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} variant="h5">{DBToDateText(userInfo.expired_date)}</Typography>
                            </div>
                        }
                    </div>
                    :
                    <Loading />
            }
        </>
    )
}
export default Profile;