
import { ArrowPathIcon, ArrowRightOnRectangleIcon, BellIcon, CpuChipIcon, IdentificationIcon, KeyIcon, MinusCircleIcon, PlusCircleIcon, TableCellsIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react'
import { IPageItem, IPages } from '../../models/AdminPanel';
import Connections from './Connections/Connections';
import ConnectionsAdd from './Connections/ConnectionsAdd';
import DeviceDatas from './DeviceDatas/DeviceDatas';
import DeviceAdd from './Devices/DeviceAdd';
import Devices from './Devices/Devices';
import Profile from '../Profile/Profile';
import ProfileEdit from '../Profile/ProfileEdit';
import ProfilePasswordUpdate from '../Profile/ProfilePasswordUpdate';
import Users from './Users/Users';
import UsersAdd from './Users/UsersAdd';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import AboutPages from './AboutPages/AboutPages';

interface Props {
    onLogOut: () => void
}

const icon = {
    className: "w-5 h-5 text-inherit",
};
const AdminPanel = (props: Props) => {
    const pages: IPages[] = [
        {
            index: 1,
            icon: <CpuChipIcon {...icon} />,
            name: "Cihazlar",
            subNav: [
                {
                    index: 11,
                    name: "Cihazlarım",
                    // icon: <DeviceTabletIcon {...icon} />,
                    icon: <CpuChipIcon {...icon} />,
                    onClick: () => {
                        setIsOpen(!isOpen);
                    },
                    element: <Devices />
                },
                {
                    index: 12,
                    name: "Cihaz Ekle",
                    icon: <PlusCircleIcon {...icon} />,
                    onClick: () => {
                        setIsOpen(!isOpen);
                    },
                    element: <DeviceAdd onAdded={() => {
                        pages[0].subNav && setCurrentPage(pages[0].subNav[0])
                    }} />
                },
            ]

        },
        {
            index: 2,
            icon: <UserCircleIcon {...icon} />,
            name: "Kullanıcılar",
            onClick: () => {
                setIsOpen(!isOpen);
            },
            subNav: [
                {
                    index: 21,
                    name: "Kullanıcılarım",
                    icon: <UserCircleIcon {...icon} />,
                    onClick: () => {
                        setIsOpen(!isOpen);
                    },
                    element: <Users />
                },
                {
                    index: 22,
                    name: "Kullanıcı Ekle",
                    icon: <PlusCircleIcon {...icon} />,
                    onClick: () => {
                        setIsOpen(!isOpen);
                    },
                    element: <UsersAdd onAdded={() => {
                        pages[1].subNav && setCurrentPage(pages[1].subNav[0])
                    }} />
                }
            ]
        },
        {
            index: 3,
            icon: <TableCellsIcon {...icon} />,
            name: "Bağlantılar",
            onClick: () => {
                setIsOpen(!isOpen);
            },
            subNav: [
                {
                    index: 31,
                    name: "Bağlantıları Görüntüle",
                    icon: <TableCellsIcon {...icon} />,
                    onClick: () => {
                        setIsOpen(!isOpen);
                    },
                    element: <Connections />
                },
                {
                    index: 32,
                    name: "Bağlantı Ekle",
                    icon: <PlusCircleIcon {...icon} />,
                    onClick: () => {
                        setIsOpen(!isOpen);
                    },
                    element: <ConnectionsAdd onAdded={() => {
                        pages[2].subNav && setCurrentPage(pages[2].subNav[0])
                    }} />
                },
            ]
        },
        {
            index: 4,
            icon: <BellIcon {...icon} />,
            name: "Profilim",
            onClick: () => {
                setIsOpen(!isOpen);
            },
            subNav: [
                {
                    index: 41,
                    name: "Profilim",
                    icon: <UserCircleIcon {...icon} />,
                    onClick: () => {
                        setIsOpen(!isOpen);
                    },
                    element: <Profile />
                },
                {
                    index: 42,
                    name: "Profili Güncelle",
                    icon: <ArrowPathIcon {...icon} />,
                    onClick: () => {
                        setIsOpen(!isOpen);
                    },
                    element: <ProfileEdit onUpdated={() => {
                        pages[3].subNav && setCurrentPage(pages[3].subNav[0])
                    }} />
                },
                {
                    index: 43,
                    name: "Şifre Değiştir",
                    icon: <KeyIcon {...icon} />,
                    onClick: () => {
                        setIsOpen(!isOpen);
                    },
                    element: <ProfilePasswordUpdate onUpdated={() => {
                        pages[3].subNav && setCurrentPage(pages[3].subNav[0])
                    }} />
                },

            ]
        },
        {
            index: 5,
            icon: <TableCellsIcon {...icon} />,
            name: "Cihaz Verileri",
            element: <DeviceDatas />,
            onClick: () => { setCurrentPage(pages[4]) },
        },
        {
            index: 6,
            icon: <IdentificationIcon {...icon} />,
            name: "İletişim Formları",
            element: <AboutPages />,
            onClick: () => { setCurrentPage(pages[5]) },
        },
        {
            index: 7,
            icon: <ArrowRightOnRectangleIcon {...icon} />,
            name: "Çıkış Yap",
            onClick: () => { props.onLogOut() },
        },
    ];


    const [currentPage, setCurrentPage] = useState<IPageItem>(pages[0].subNav ? pages[0].subNav[0] : pages[0]);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        const page = localStorage.getItem("page");
        if (page && page.length < 4) {
            const index = Number.parseInt(page);
            pages.map((item) => {
                if (item.index === index) {
                    setCurrentPage(item);
                }
                else if (item.subNav) {
                    item.subNav.map((item2) => {
                        if (item2.index === index) {
                            setCurrentPage(item2);
                        }
                        return null
                    })
                }
                return null
            })
        }
    }, [])


    useEffect(() => {
        if (currentPage) {
            localStorage.setItem("page", currentPage.index.toString());
        }
    }, [currentPage])

    return (
        <>
            <div className="min-h-full">
                <Sidebar
                    title="Admin Paneli"
                    setCurrentPage={setCurrentPage}
                    pages={pages}
                    currentPage={currentPage}
                    isOpen={isOpen}
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }} />
                <div className="p-4 xl:ml-80">
                    <Header
                        isOpen={isOpen}
                        onSidebarClick={() => {
                            setIsOpen(!isOpen);
                        }}
                        currentPageName={currentPage.name}
                    />
                    {currentPage.element}
                    {/* <main>
                    <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                        <div className="px-4 py-6 sm:px-0">
                            <div className="h-96 rounded-lg border-4 border-dashed border-gray-200" />
                        </div>
                    </div>
                </main> */}

                </div>
            </div>
        </>
    )
}
export default AdminPanel;