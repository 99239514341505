import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../API/API";
import { IConnections } from "../../../models/AdminPanel";
import { Button } from "@material-tailwind/react";
import DeleteModal from "../../../components/DeleteModal";
import ConnectionUpdateModal from "./ConnectionUpdateModal";

const Connections = () => {
    const [connections, setConnections] = useState<IConnections[]>()


    const GetConnections = () => {
        API.getInstance().GetConnectionDeviceUser()
            .then((response) => {
                setConnections(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    };

    useEffect(() => {
        GetConnections();
    }, [])


    const [updatedConnection, setUpdatedConnection] = useState<IConnections>();
    const [deletedId, setDeletedId] = useState("");

    const DeleteConnection = () => {
        API.getInstance().DeleteConnectionDeviceUser(deletedId)
            .then((response) => {
                toast.success(response.data.message);
                GetConnections();
                setDeletedId("");
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    };
    return (
        <div className="flex align-center flex-col">
            <h5 className="mt-5 text-center	 block font-sans text-l font-semibold leading-snug tracking-normal text-inherit antialiased">{"Hangi kullanıcıların hangi cihazlara erişim olduğunu görebilirsiniz."}</h5>
            <div className="overflow-x-auto mt-5 shadow	 ">
                <table className="table w-full">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>E-mail</th>
                            <th>Seri No</th>
                            <th>Sıcaklık</th>
                            <th>İvme X</th>
                            <th>İvme Y</th>
                            <th>İvme Z</th>
                            <th>A.Hız X</th>
                            <th>A.Hız Y</th>
                            <th>A.Hız Z</th>
                            <th>Mag X</th>
                            <th>Mag Y</th>
                            <th>Mag Z</th>
                            <th>Roll</th>
                            <th>Pitch</th>
                            <th>Yaw</th>
                            <th>İnd. Roll</th>
                            <th>İnd. Pitch</th>
                            <th>İnd. Yaw</th>
                            <th>Sapma</th>
                            <th>İnd. Sapma</th>
                            <th>Vgs</th>
                            <th>Batarya Volt</th>
                            <th>Güncelle</th>
                            <th>Sil</th>
                        </tr>
                    </thead>
                    <tbody>
                        {connections?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <th>{item.id}</th>
                                    <th>{item.email}</th>
                                    <th>{item.device_seri_no}</th>
                                    <th>{item.temp ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.acc_x ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.acc_y ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.acc_z ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.gyro_x ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.gyro_y ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.gyro_z ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.mag_x ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.mag_y ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.mag_z ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.angle_x ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.angle_y ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.angle_z ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.refined_angle_x ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.refined_angle_y ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.refined_angle_z ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.deflection ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.refined_deflection ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.millis ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>{item.battery_voltage ? <CheckCircleIcon color="green" height={20} /> : <XCircleIcon color="red" height={20} />}</th>
                                    <th>
                                        <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} variant="gradient" color="green" onClick={() => setUpdatedConnection(item)}>
                                            <span>Güncelle</span>
                                        </Button>
                                    </th>
                                    <th>
                                        <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} variant="gradient" color="red" onClick={() => setDeletedId(item.id!)}>
                                            <span>Sil</span>
                                        </Button>
                                    </th>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>
            {
                deletedId != "" &&
                <DeleteModal
                    title={"Bağlantıyı Sil"}
                    description={"Bağlantıyı silmek istiyor musunuz?"}
                    onCancel={() => setDeletedId("")}
                    onOk={DeleteConnection} />
            }


            {
                updatedConnection &&
                <ConnectionUpdateModal
                    onUpdated={() => {
                        setUpdatedConnection(undefined);
                        GetConnections();
                    }}
                    onCancel={() => setUpdatedConnection(undefined)}
                    selectedConnection={updatedConnection}
                />

            }

        </div>
    )
}
export default Connections;