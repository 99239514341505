export const radioButtons = [
    { text: "İvme x", type: "acc_x" },
    { text: "İvme y", type: "acc_y" },
    { text: "İvme z", type: "acc_z" },

    { text: "Roll", type: "angle_x" },
    { text: "Pitch", type: "angle_y" },
    { text: "Yaw", type: "angle_z" },
    { text: "Sapma", type: "deflection" },

    { text: "İndirgenmiş Roll", type: "refined_angle_x" },
    { text: "İndirgenmiş Pitch", type: "refined_angle_y" },
    { text: "İndirgenmiş Yaw", type: "refined_angle_z" },
    { text: "İndirgenmiş Sapma", type: "refined_deflection" },

    { text: "Gyro x", type: "gyro_x" },
    { text: "Gyro y", type: "gyro_y" },
    { text: "Gyro z", type: "gyro_z" },

    { text: "Mag x", type: "mag_x" },
    { text: "Mag y", type: "mag_y" },
    { text: "Mag z", type: "mag_z" },

    { text: "Sıcaklık", type: "temp" },

    { text: "Veri G. Süresi", type: "millis" },

    { text: "Batarya", type: "battery_voltage" }
]