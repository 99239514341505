import React from 'react'
import { Typography } from "@material-tailwind/react";
import DatePicker from "react-datepicker";
import { ExampleCustomInput } from './DatePickerInput';
import "react-datepicker/dist/react-datepicker.css";


interface Props {
    date: Date | null;
    setDate: (date: Date | null) => void;
    title: string;
    minDate?: Date | null;
    maxDate?: Date | null;
}
const CustomDate = ({ date, setDate, title, minDate, maxDate }: Props) => {
    return (
        <div className='ml-2 mr-2' style={{ marginTop: "10px", zIndex: 15 }}>
            <Typography placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} className="text-center" color="gray" variant="h6">{title}</Typography>
            <DatePicker
                maxDate={maxDate}
                minDate={minDate}
                placeholderText={title}
                locale="tr"
                selected={date}
                onChange={(date) => setDate(date)}
                showTimeSelect
                customInput={<ExampleCustomInput
                    //@ts-ignore
                    placeholderText={title}
                />}
                timeIntervals={10}
                timeCaption={"time"}
                dateFormat="dd/MM/YYYY HH:mm"
                timeFormat='HH:mm'
                popperClassName="custom-datepicker"
            />
        </div>

    )
}

export default CustomDate