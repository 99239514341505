import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../API/API";
import { IUsers } from "../../../models/AdminPanel";
import { DBToDateText } from "../../../utils/DBToDateText";
import Loading from "../../../components/Loading";
import { Button } from "@material-tailwind/react";
import DeleteModal from "../../../components/DeleteModal";
import UserUpdateModal from "./components/UserUpdateModal";

const Users = () => {
    const [users, setUsers] = useState<IUsers[]>()
    const [loading, setLoading] = useState(false);
    const [deletedId, setDeletedId] = useState("");
    const [updatedUser, setUpdatedUser] = useState<IUsers>();
    const GetUsers = () => {
        setLoading(true);
        API.getInstance().GetUsers()
            .then((response) => {
                setUsers(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            }).finally(() => setLoading(false))
    };

    useEffect(() => {
        GetUsers();
    }, [])

    const DeleteUser = () => {
        API.getInstance().DeleteUser(deletedId)
            .then((response) => {
                toast.success(response.data.message);
                GetUsers();
                setDeletedId("");
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    };

    return (
        <>
            {loading ? <Loading />
                :
                <div className="overflow-x-auto mt-5 shadow	 ">
                    <table className="table w-full">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Rol</th>
                                <th>Email</th>
                                <th>Şifresi</th>
                                <th>Ad</th>
                                <th>Soyad</th>
                                <th>Bitiş Tarihi</th>
                                <th>Oluşturma Tarihi</th>
                                <th>Güncelle</th>
                                <th>Sil</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <th>{item.user_id}</th>
                                        <th>{item.role}</th>
                                        <th>{item.email}</th>
                                        <th>{item.password}</th>
                                        <th>{item.name}</th>
                                        <th>{item.surname}</th>
                                        {item.role === "ADMIN"
                                            ?
                                            <th>YOK</th>
                                            :
                                            <th>{DBToDateText(item.expired_date)}</th>
                                        }
                                        <th>{DBToDateText(item.created_date)}</th>
                                        <th>
                                            <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} variant="gradient" color="green" onClick={() => { setUpdatedUser(item) }}>
                                                <span>Güncelle</span>
                                            </Button>
                                        </th>
                                        <th>
                                            <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} variant="gradient" color="red" onClick={() => setDeletedId(item.user_id)}>
                                                <span>Sil</span>
                                            </Button>
                                        </th>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
            }
            {
                deletedId != "" &&
                <DeleteModal
                    title={"Kullanıcıyı Sil"}
                    description={"Kullanıcıyı silmek ve tanımlı cihazları kaldırmak istiyor musunuz?"}
                    onCancel={() => setDeletedId("")}
                    onOk={() => DeleteUser()} />
            }
            {
                updatedUser &&
                <UserUpdateModal
                    onUpdated={() => {
                        setUpdatedUser(undefined);
                        GetUsers();
                    }}
                    onCancel={() => setUpdatedUser(undefined)}
                    selectedUser={updatedUser}
                />

            }
        </>
    )
}
export default Users;