import { FC } from "react"
import DeviceUpdateModal from "../Devices/DeviceUpdateModal";
import { IDevice } from "../../../models/AdminPanel";
interface Props {
    onCancel: () => void
    selectedDevice: IDevice
    onUpdated: () => void
}
const SettingsModal: FC<Props> = (props) => {
    const { onCancel, onUpdated } = props;
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-sm">
                    <div className="border-0 rounded-lg shadow-lg relative flex items-center flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 pb-2 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">Ayarlar</h3>
                        </div>
                        <div style={{ overflowY: "scroll", height: "80vh", padding: 20 }}>
                            <DeviceUpdateModal
                                onUpdated={onUpdated}
                                onCancel={onCancel}
                                selectedDevice={props.selectedDevice} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}
export default SettingsModal;

