import { FC, useState, useRef } from "react"
import { IUserDeviceData } from "../../../models/Response";
import { Line as LineCharJS } from 'react-chartjs-2';
import { Chart as ChartJS } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from "moment";
import CHART_COLORS from "../../../constants/ChartColors";
import zoomPlugin from 'chartjs-plugin-zoom';
import Chart from 'chart.js/auto';

// Register the zoom plugin
Chart.register(zoomPlugin);
Chart.register(annotationPlugin);

interface Props {
  data: IUserDeviceData[];
  height: number;
  statik: "0" | "1"
}



interface ChartRefType {
  resetZoom: () => void;
}

const DefAngleChartJS: FC<Props> = ({ data, statik }) => {
  const [chartData] = useState<IUserDeviceData[]>(data);
  const chartRef = useRef<ChartRefType | null>(null); // Specify the type of chartRef
  const chartRef2 = useRef<ChartRefType | null>(null); // Specify the type of chartRef


  const resetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  const resetZoom2 = () => {
    if (chartRef2 && chartRef2.current) {
      chartRef2.current.resetZoom();
    }
  };

  let datasests = [];

  if (chartData[0].refined_deflection) {
    datasests.push(
      {
        label: 'İndirgenmiş Sapma (mm)',
        data: chartData.map((item) => item.refined_deflection),
        borderColor: CHART_COLORS.purple,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: true,
      }
    )
  }

  if (chartData[0].refined_angle_x) {
    datasests.push(
      {
        label: 'İndirgenmiş Roll (°)',
        data: chartData.map((item) => item.refined_angle_x),
        borderColor: CHART_COLORS.blue,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
      }
    )
  }
  if (chartData[0].refined_angle_y) {
    datasests.push(
      {
        label: 'İndirgenmiş Pitch (°)',
        data: chartData.map((item) => item.refined_angle_y),
        borderColor: CHART_COLORS.green,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
      }
    )
  }
  if (chartData[0].refined_angle_z) {
    datasests.push(
      {
        label: 'İndirgenmiş Yaw (°)',
        data: chartData.map((item) => item.refined_angle_z),
        borderColor: CHART_COLORS.yellow,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: true,
      }
    )
  }
  return (
    <>
      {chartData.length > 0 && (chartData[0].refined_deflection || chartData[0].refined_angle_x || chartData[0].refined_angle_y || chartData[0].refined_angle_z) && (
        <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '70vh' }}>
          <button onClick={resetZoom} style={{ border: '2px solid black', padding: '1px', borderRadius: '5px' }}>Zoom Reset</button>
          <LineCharJS
            ref={(ref) => {
              if (ref) {
                chartRef.current = ref;
              }
            }}
            options={
              {
                responsive: true,
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
                plugins: {
                  title: {
                    display: false,
                    text: 'İndirgenmiş Sapma / Açı Grafiği'
                  },
                  zoom: {
                    pan: {
                      enabled: true,
                      mode: 'xy',
                    },
                    zoom: {
                      mode: 'xy',
                      wheel: { enabled: true },
                      pinch: { enabled: true },
                    },
                  },
                },
                scales: {
                  x: {
                    display: true,
                    ticks: {
                      callback: function (value, index, ticks) {
                        const date = this.getLabelForValue(value as number)
                        if (statik == "1") {
                          return moment(date, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY");
                        } else {
                          return moment(date, "DD/MM/YYYY HH:mm:ss").format("HH:mm:ss");
                        }
                      }
                    }
                  },
                  y: {
                    type: "linear",
                    display: true,
                    position: 'left',
                  },
                }
              }
            }
            data={{
              labels: chartData.map((item) => moment(item.date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")),
              datasets: datasests
            }}
          />
        </div>
      )}
      {(chartData.length > 0 && (chartData[0].refined_angle_x || chartData[0].refined_angle_y)) && (
        <div style={{ marginTop: '30px', marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '70vh' }}>
          <button onClick={resetZoom2} style={{ border: '2px solid black', padding: '1px', borderRadius: '5px' }}>Zoom Reset</button>
          <ChartJS
            type="scatter"
            ref={(ref) => {
              if (ref) {
                chartRef2.current = ref;
              }
            }}
            data={{
              datasets: [
                {
                  // İlk veri noktasını gösteren scatter grafiği
                  label: 'İlk Veri',
                  data: [{ x: chartData[0].refined_angle_x, y: chartData[0].refined_angle_y }],
                  backgroundColor: 'green',
                  borderColor: 'green',
                  pointRadius: 7,
                  hidden: false,
                },
                {
                  // Son veri noktasını gösteren scatter grafiği
                  label: 'Son Veri',
                  data: [{ x: chartData[chartData.length - 1].refined_angle_x, y: chartData[chartData.length - 1].refined_angle_y }],
                  backgroundColor: 'red',
                  borderColor: 'red',
                  pointRadius: 10,
                  hidden: false,
                },
                {
                  // Diğer tüm veri noktalarını gösteren scatter grafiği
                  label: 'Diğer Veriler',
                  data: chartData.slice(1, chartData.length - 1).map(item => ({ x: item.refined_angle_x, y: item.refined_angle_y })),
                  backgroundColor: 'blue',
                  borderColor: 'blue',
                  pointRadius: 5,
                  hidden: true,
                }
              ]
            }}
            options={{
              responsive: true,
              scales: {
                x: {
                  display: true,
                  title: {
                    display: true,
                    text: ' İnd. Roll (°)'
                  }
                },
                y: {
                  display: true,
                  title: {
                    display: true,
                    text: 'İnd. Pitch (°)'
                  }
                }
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      var label = context.dataset.label || '';
                      if (label) {
                        label += ': ';
                      }
                      if (context.parsed.x !== null) {
                        label += `İnd. Roll (°): ${Number(context.parsed.x).toFixed(3)}`;
                      }
                      if (context.parsed.y !== null) {
                        label += `, İnd. Pitch (°): ${Number(context.parsed.y).toFixed(3)}`;
                      }
                      return label;
                    }
                  }
                },
                annotation: {
                  annotations: {
                    line1: {
                      type: 'line',
                      xMin: chartData[0].refined_angle_x,
                      xMax: chartData[0].refined_angle_x,
                      borderColor: 'green',
                      borderWidth: 2,
                    },
                    line2: {
                      type: 'line',
                      yMin: chartData[0].refined_angle_y,
                      yMax: chartData[0].refined_angle_y,
                      borderColor: 'green',
                      borderWidth: 2,
                    },
                    line3: {
                      type: 'line',
                      xMin: chartData[chartData.length - 1].refined_angle_x,
                      xMax: chartData[chartData.length - 1].refined_angle_x,

                      borderColor: 'red',
                      borderWidth: 2,
                    },
                    line4: {
                      type: 'line',
                      yMin: chartData[chartData.length - 1].refined_angle_y,
                      yMax: chartData[chartData.length - 1].refined_angle_y,
                      borderColor: 'red',
                      borderWidth: 2,
                    },
                  },
                },
                zoom: {
                  pan: {
                    enabled: true,
                    mode: 'xy',
                  },
                  zoom: {
                    mode: 'xy',
                    wheel: { enabled: true },
                    pinch: { enabled: true },
                  },
                },
              }
            }}
          />
        </div>
      )}
    </>
  )
}

export default DefAngleChartJS;
