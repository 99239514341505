import React, { FC } from "react";
import { Button, Typography } from "@material-tailwind/react";


interface Props {
  title: string;
  description: string;
  onCancel: () => void;
  onOk: () => void;
  cancelText?: string;
  okText?: string;
}

const DeleteModal: FC<Props> = (props) => {
  const { title, description, onCancel, onOk, cancelText, okText } = props;

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-sm">
          <div className="border-0 rounded-lg shadow-lg relative flex items-center flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 pb-2 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">{title}</h3>
            </div>
            <div className="flex m-3 flex-col items-center w-72 mb-1 ">
            <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="red" variant="h6" >{description}</Typography>

              <div className="flex mt-6 items-center justify-around w-72 mb-5">
                <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="green" onClick={onCancel} >{cancelText ? cancelText : "Vazgeç"}</Button>
                <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="red" onClick={onOk} >{okText ? okText : "Sil"}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default DeleteModal;