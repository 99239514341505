import React, { FC, ReactPortal } from "react"
interface Props {
    title: string
    content: React.ReactNode;
}
const PdfModal: FC<Props> = (props) => {
    const { content, title } = props;
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w">
                    <div className="border-0 rounded-lg shadow-lg relative flex items-center flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 pb-2 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">{title}</h3>
                        </div>
                        <div className="m-2">
                            {content}
                        </div>

                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>)
}
export default PdfModal;

