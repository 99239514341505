import { IPageItem, IPages } from "../models/AdminPanel";
import { Button, Typography, } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import SideBarItem from "./SidebarItem";

interface Props {
    item: IPages
    setCurrentPage: (item: IPageItem) => void
    currentPage: IPageItem
}

const SideBarItemCollapse = (props: Props) => {
    const { item } = props;
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            const page = localStorage.getItem("page");
            if (page && page.length < 4) {
                const index = Number.parseInt(page);
                if (item.subNav?.findIndex((item) => item.index === index) !== -1) {
                    setOpen(true);
                }
            }
        }, 100)
    }, [props.currentPage]);
    return (
        <>
            <li key={item.name}>
                <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                    onClick={() => {
                        setOpen(!open);
                    }}
                    variant={item.index === props.currentPage.index ? "gradient" : "text"}
                    color={item.index === props.currentPage.index ? "blue" : "white"}
                    className="flex items-center gap-4 px-4 capitalize relative "
                    fullWidth
                >
                    {item.icon}
                    <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                        color="inherit"
                        className="font-medium capitalize"
                    >
                        {item.name}
                    </Typography>
                    <div className="absolute right-2">
                        {open ?
                            <ChevronDownIcon className="w-6 h-6" />
                            :
                            <ChevronUpIcon className="w-6 h-6" />}
                    </div>
                </Button>
                {open &&
                    <>
                        {item.subNav?.map((itemm, index) => {
                            return (
                                <div className="flex m-1 ml-5" key={index}>
                                    <SideBarItem
                                        subNavItem={itemm}
                                        item={item}
                                        setCurrentPage={() => props.setCurrentPage(itemm)}
                                        currentPage={props.currentPage}
                                    />
                                </div>
                            )
                        })}
                    </>
                }
            </li></>
    )
}
export default SideBarItemCollapse;