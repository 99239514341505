import { Select, Option, Button, Radio, } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import API from "../../../API/API";
import { IConnections, IDevice, IUsers } from "../../../models/AdminPanel";
import { radioButtons } from "./Constant/ConnectionConstants";
interface Props {
    onAdded: () => void
}

const ConnectionsAdd = (props: Props) => {
    useEffect(() => {
        GetUsers();
        GetDevices();
    }, [])

    const [selectedUserId, setSelectedUserId] = useState("")
    const [users, setUsers] = useState<IUsers[]>()
    const GetUsers = () => {
        API.getInstance().GetUsers()
            .then((response) => {
                setUsers(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }

    const [devices, setDevices] = useState<IDevice[]>()
    const [selectedDeviceId, setSelectedDeviceId] = useState("")
    const GetDevices = () => {
        API.getInstance().GetDevices()
            .then((response) => {
                setDevices(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }

    const [radioValues, setRadioValues] = useState<IConnections>(
        {
            "temp": true,
            "acc_x": true,
            "acc_y": true,
            "acc_z": true,
            "gyro_x": true,
            "gyro_y": true,
            "gyro_z": true,
            "mag_x": true,
            "mag_y": true,
            "mag_z": true,
            "angle_x": true,
            "angle_y": true,
            "angle_z": true,
            "refined_angle_x": true,
            "refined_angle_y": true,
            "refined_angle_z": true,
            "deflection": true,
            "refined_deflection": true,
            "millis": true,
            "battery_voltage": true
        });

    const CreateConnection = () => {
        const object = Object.assign({}, radioValues, { user_id: selectedUserId, device_id: selectedDeviceId });
        API.getInstance().CreateConnectionDeviceUser(object)
            .then((response) => {
                toast.success(response.data.message);
                // GetUsers();
                setSelectedUserId("");
                setSelectedDeviceId("");
                props.onAdded();
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    };
    return (
        <div className="flex w-full mt-5 flex-col items-center">
            <h5 className="block font-sans text-l font-semibold leading-snug tracking-normal text-inherit antialiased">{"Bağlantı eklemek istediğiniz kullanıcıyı seçiniz"}</h5>
            <div className="mt-4 mb-4 w-72" >
                {users &&
                    <Select placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} disabled={users.length === 0} value={selectedUserId} onChange={(item) => {
                        setSelectedUserId(item ? item.toString() : "")
                    }}
                        label={users.length === 0 ? "Kullanıcı bulunamamıştır." : "Kullanıcı seçiniz"}>
                        {users.map((item, index) => {
                            return (
                                <Option key={index} value={item.user_id} >{`${item.user_id} - ${item.email}`}</Option>
                            )
                        })
                        }
                    </Select>
                }
            </div>
            <div className="mb-4 w-72" >
                {devices &&
                    <Select placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} disabled={devices.length === 0} value={selectedDeviceId} onChange={(item) => {
                        setSelectedDeviceId(item ? item.toString() : "")
                    }}
                        label={devices.length === 0 ? "Cihaz bulunamamıştır." : "Cihaz seçiniz"}>
                        {devices.map((item, index) => {
                            return (
                                <Option key={index} value={item.device_id?.toString()} >{`${item.device_id} - ${item.device_seri_no}`}</Option>
                            )
                        })
                        }
                    </Select>
                }
            </div>
            <h5 className="block font-sans text-l font-semibold leading-snug tracking-normal text-inherit antialiased">{"Verilere Erişimleri Belirle"}</h5>
            {/* {radioButtons.map((item, index) => {
                return (
                    <div className="flex items-center	">
                        <h5 className="block font-sans text-l font-semibold leading-snug tracking-normal text-inherit antialiased">{`${item.text} =>`}</h5>
                        <Radio onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} onChange={() => { setRadioValues({ ...radioValues, [item.type]: true }) }} id={item.type + "-show"} name={"Radio" + (index + 1).toString()} color="green" label="Var" defaultChecked />
                        <Radio onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} onChange={() => { setRadioValues({ ...radioValues, [item.type]: false }) }} id={item.type + "-hide"} name={"Radio" + (index + 1).toString()} color="red" label="Yok" />
                    </div>
                )
            })} */}

            <div className="card w-70 sm:w-96 md:w-96 xl:w-96 lg:w-96 mt-2 bg-neutral text-neutral-content" style={{ padding: "0px" }}>
                <div className="card-body items-center text-center">
                    {
                        radioButtons.slice(0, 3).map((item, index) => {
                            return (
                                <>
                                    <div className="flex flex-row">
                                        <h2 className="card-title">{item.text}</h2>
                                        <Radio onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                            labelProps={{ className: "text-white" }}
                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: true }) }}
                                            id={item.type + "-show"}
                                            name={`Radio-${item.type}`}
                                            color="green"
                                            label="Var"
                                            crossOrigin="anonymous"
                                            defaultChecked
                                        />
                                        <Radio onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                            labelProps={{ className: "text-white" }}
                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: false }) }}
                                            id={item.type + "-hide"}
                                            name={`Radio-${item.type}`}
                                            color="red"
                                            label="Yok"
                                            crossOrigin="anonymous"
                                        />
                                    </div>
                                </>
                            )
                        }
                        )
                    }
                </div>
            </div>

            <div className="card w-70 sm:w-96 md:w-96 xl:w-96  mt-2 bg-neutral text-neutral-content" style={{ padding: "0px" }}>
                <div className="card-body items-center text-center">
                    {
                        radioButtons.slice(3, 7).map((item, index) => {
                            return (
                                <>
                                    <div className="flex flex-row">
                                        <h2 className="card-title">{item.text}</h2>
                                        <Radio onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                            labelProps={{ className: "text-white" }}
                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: true }) }}
                                            id={item.type + "-show"}
                                            name={`Radio-${item.type}`}
                                            color="green"
                                            label="Var"
                                            crossOrigin="anonymous"
                                            defaultChecked

                                        />
                                        <Radio onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                            labelProps={{ className: "text-white" }}
                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: false }) }}
                                            id={item.type + "-hide"}
                                            name={`Radio-${item.type}`}
                                            color="red"
                                            label="Yok"
                                            crossOrigin="anonymous"
                                        />
                                    </div>
                                </>
                            )
                        }
                        )
                    }
                </div>
            </div>

            <div className="card w-70 sm:w-96 md:w-96 xl:w-96  mt-2 bg-neutral text-neutral-content" style={{ padding: "0px" }}>
                <div className="card-body items-center text-center">
                    {
                        radioButtons.slice(7, 11).map((item, index) => {

                            return (
                                <>
                                    <div className="flex flex-row">
                                        <h2 className="card-title">{item.text}</h2>
                                        <Radio onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                            labelProps={{ className: "text-white" }}
                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: true }) }}
                                            id={item.type + "-show"}
                                            name={`Radio-${item.type}`}
                                            color="green"
                                            label="Var"
                                            crossOrigin="anonymous"
                                            defaultChecked

                                        />
                                        <Radio onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                            labelProps={{ className: "text-white" }}
                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: false }) }}
                                            id={item.type + "-hide"}
                                            name={`Radio-${item.type}`}
                                            color="red"
                                            label="Yok"
                                            crossOrigin="anonymous"
                                        />
                                    </div>
                                </>
                            )
                        }
                        )
                    }
                </div>
            </div>

            <div className="card w-70 sm:w-96 md:w-96 xl:w-96  mt-2 bg-neutral text-neutral-content" style={{ padding: "0px" }}>
                <div className="card-body items-center text-center">
                    {
                        radioButtons.slice(11, 14).map((item, index) => {
                            return (
                                <>
                                    <div className="flex flex-row">
                                        <h2 className="card-title">{item.text}</h2>
                                        <Radio onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} labelProps={{ className: "text-white" }}
                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: true }) }}
                                            id={item.type + "-show"}
                                            name={`Radio-${item.type}`}
                                            color="green"
                                            label="Var"
                                            crossOrigin="anonymous"
                                            defaultChecked
                                        />
                                        <Radio onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} labelProps={{ className: "text-white" }}
                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: false }) }}
                                            id={item.type + "-hide"}
                                            name={`Radio-${item.type}`}
                                            color="red"
                                            label="Yok"
                                            crossOrigin="anonymous"
                                        />
                                    </div>
                                </>
                            )
                        }
                        )
                    }
                </div>
            </div>

            <div className="card w-70 sm:w-96 md:w-96 xl:w-96  mt-2 bg-neutral text-neutral-content" style={{ padding: "0px" }}>
                <div className="card-body items-center text-center">
                    {
                        radioButtons.slice(14, 17).map((item, index) => {
                            return (
                                <>
                                    <div className="flex flex-row">
                                        <h2 className="card-title">{item.text}</h2>
                                        <Radio onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} labelProps={{ className: "text-white" }}
                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: true }) }}
                                            id={item.type + "-show"}
                                            name={`Radio-${item.type}`}
                                            color="green"
                                            label="Var"
                                            crossOrigin="anonymous"
                                            defaultChecked

                                        />
                                        <Radio onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} labelProps={{ className: "text-white" }}
                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: false }) }}
                                            id={item.type + "-hide"}
                                            name={`Radio-${item.type}`}
                                            color="red"
                                            label="Yok"
                                            crossOrigin="anonymous"
                                        />
                                    </div>
                                </>
                            )
                        }
                        )
                    }
                </div>
            </div>

            <div className="card w-70 sm:w-96 md:w-96 xl:w-96  mt-2 mb-3 bg-neutral text-neutral-content" style={{ padding: "0px" }}>
                <div className="card-body items-center text-center">
                    {
                        radioButtons.slice(17, 20).map((item, index) => {
                            return (
                                <>
                                    <div className="flex flex-row">
                                        <h2 className="card-title">{item.text}</h2>
                                        <Radio onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} labelProps={{ className: "text-white" }}
                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: true }) }}
                                            id={item.type + "-show"}
                                            name={`Radio-${item.type}`}
                                            color="green"
                                            label="Var"
                                            crossOrigin="anonymous"
                                            defaultChecked
                                        />
                                        <Radio onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} labelProps={{ className: "text-white" }}
                                            onChange={() => { setRadioValues({ ...radioValues, [item.type]: false }) }}
                                            id={item.type + "-hide"}
                                            name={`Radio-${item.type}`}
                                            color="red"
                                            label="Yok"
                                            crossOrigin="anonymous"
                                        />
                                    </div>
                                </>
                            )
                        }
                        )
                    }
                </div>
            </div>
            <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} disabled={selectedUserId === "" || selectedDeviceId === ""} onClick={() => { CreateConnection() }} variant="gradient">
                Bağlantı Oluştur
            </Button>
        </div >
    )
}
export default ConnectionsAdd;